import { MyProductsApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { MyProductsListRequestDto, MyProductsListResponseDto } from "./dtos";
import { AxiosResponse } from "axios";
import { MyProduct } from "../_models/MyProduct.model";

type Data = MyProduct[];
const responseToData = (
  response: AxiosResponse<MyProductsListResponseDto>
): Data => {
  return response.data.data.map((data) => new MyProduct(data));
};

const MyProductListEndpoint = new EndpointRequest<
  MyProductsListRequestDto,
  MyProductsListResponseDto,
  Data
>("MY_PRODUCTS", MyProductsApi.getProducts, responseToData);

export { MyProductListEndpoint };
