import { MyProductsUpdatesApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import {
  SetMyProductUpdateReadRequestDto,
  SetMyProductUpdateReadResponseDto,
} from "./dtos";
import { AxiosResponse } from "axios";

type Data = null;
const responseToData = (
  response: AxiosResponse<SetMyProductUpdateReadResponseDto>
): Data => {
  return null;
};

const SetMyProductUpdateReadEndpoint = new EndpointRequest<
  SetMyProductUpdateReadRequestDto,
  SetMyProductUpdateReadResponseDto,
  Data
>("MY_PRODUCTS", MyProductsUpdatesApi.setMyProductUpdateRead, responseToData);

export { SetMyProductUpdateReadEndpoint };
