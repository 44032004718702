import { sanitize } from "dompurify";
import { PostDto } from "../one/dtos";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import parse from "date-fns/parse";
import { localeSwitches } from "../../../utils";
import { PostShort } from "./Post.short.model";

export class Post {
  id = -1;
  title = "";
  slug = "";
  content: string | null;
  excerpt: string | null;
  image: string | null;
  date: Date | null;
  related: PostShort[] = [];
  translationsSlugs: { [key: string]: string } = {};

  constructor(dto: PostDto) {
    this.id = dto.id ?? -1;
    this.title = dto.title.rendered;
    this.slug = dto.slug ?? "";
    this.content = dto.content.rendered ? sanitize(dto.content.rendered) : null;
    this.excerpt = dto.excerpt.rendered ? sanitize(dto.excerpt.rendered) : null;
    this.image = isString(dto.thumbnail) ? dto.thumbnail : null;
    this.date = dto.date
      ? parse(dto.date, "yyyy-MM-dd'T'HH:mm:ss", new Date())
      : null;

    if (isArray(dto.acf.news_related) && dto.acf.news_related.length > 1) {
      this.related = dto.acf.news_related.map((data) => {
        return new PostShort({
          ...data,
          isRelatedPost: true,
        });
      });
    }

    this.translationsSlugs = Object.entries(dto.translations_slug).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      return {
        ...acc,
        [localeSwitches.serverToBrowser(key)]: value,
      };
    }, {});
  }
}
