import React, { FC } from "react";
import stl from "./index.module.scss";
import styledStl from "../../assets/styles/styledHtmlContent.module.scss";
import cn from "classnames";
import { ProductModification } from "../../endpoints/products/_models/Product.model";
import { NavLink, NavLinkProps } from "react-router-dom";
import { productRoute } from "../../modules/Default/Product/route";

interface Props extends Omit<NavLinkProps, "to"> {
  data: ProductModification;
}

const ProductModificationCard: FC<Props> = ({ className, data, ...props }) => {
  const route = `${productRoute.path.replace("/:slug", "")}/${data.slug}`;

  return (
    <NavLink {...props} to={route} className={cn(className, stl.block)}>
      <div className={stl.imgWrap}>
        <img src={data.thumbnail} alt="" />
      </div>
      <div className={stl.content}>
        <h3 className={stl.title}>{data.title}</h3>
        <div
          className={cn(stl.description, styledStl.content)}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>
    </NavLink>
  );
};

export default ProductModificationCard;
