import { AuthApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { MeResponseDto } from "./dtos";

interface MeData {
  name: string;
  avatar?: string;
  defaultLanguage: string;
}

const MeEndpoint = new EndpointRequest<undefined, MeResponseDto, MeData>(
  "LOGIN",
  AuthApi.me,
  (resData, reqData, prevState) => {
    return {
      name: resData.data.name,
      avatar: resData.data.avatar_urls["24"],
      defaultLanguage: resData.data.default_language,
    };
  }
);

export { MeEndpoint };
