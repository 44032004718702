import React, { FC, useEffect, useState } from "react";
import stl from "./index.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { authenticationRoute } from "../Authenticate/route";
import { useTranslation } from "react-i18next";
import EmailForm from "./EmailForm";
import CodeForm from "./CodeForm";
import PasswordForm from "./PasswordForm";
import { callPop } from "../../../App";
import {
  SetPasswordRecovery,
  ResetPasswordRecovery,
  ValidatePasswordCodeEndpoint,
  resetRecovery,
} from "../../../endpoints/auth/recovery";

enum Steps {
  email,
  code,
  password,
}

const Recovery: FC = () => {
  const { t } = useTranslation("authModule_recovery");
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.email);
  const history = useHistory();

  useEffect(() => {
    const step1Sub = ResetPasswordRecovery.call.doneData.watch(
      ({ message }) => {
        callPop?.(message, { variant: "success" });
        setCurrentStep(Steps.code);
      }
    );
    const step2Sub = ValidatePasswordCodeEndpoint.call.doneData.watch(
      ({ message }) => {
        callPop?.(message, { variant: "success" });
        setCurrentStep(Steps.password);
      }
    );
    const step3Sub = SetPasswordRecovery.call.doneData.watch(({ message }) => {
      callPop?.(message, { variant: "success" });
      history.push(authenticationRoute.path);
    });

    return () => {
      step1Sub.unsubscribe();
      step2Sub.unsubscribe();
      step3Sub.unsubscribe();

      setCurrentStep(Steps.email);
      resetRecovery();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={stl.block}>
      <div className={stl.formWrap}>
        {currentStep === Steps.email && <EmailForm />}
        {currentStep === Steps.code && <CodeForm />}
        {currentStep === Steps.password && <PasswordForm />}
      </div>
      <p className={stl.option}>
        {t("option.text")}{" "}
        <NavLink to={authenticationRoute.path}>{t("option.link")}</NavLink>
      </p>
    </div>
  );
};

export default Recovery;
