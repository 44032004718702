import React, { FC, useEffect, useState } from "react";
import { FaqCategoriesList } from "../../../endpoints/faqCategories/list";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  FaqCategory,
  FaqCategoryEndpoint,
} from "../../../endpoints/faqCategories/one";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import Item from "./Item";

interface Props {
  data: FaqCategoriesList[0];
  isFirst?: boolean;
}

const Category: FC<Props> = ({ data, isFirst }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!isFirst);

  const [faqs, setFaqs] = useState<FaqCategory>();
  const [pending, setPending] = useState<boolean>(false);

  const read = async () => {
    setPending(true);
    await FaqCategoryEndpoint.call({ id: data.id }).then(setFaqs);
    setPending(false);
  };

  useEffect(() => {
    if (isOpen && (!faqs || faqs.length === 0)) read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Accordion
      style={{ border: "none", marginBottom: "1em" }}
      expanded={isOpen}
      onChange={() => setIsOpen(!isOpen)}
    >
      <AccordionSummary
        expandIcon={isOpen ? <FiMinusCircle /> : <FiPlusCircle />}
      >
        <Typography variant={"h3"}>{data.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {pending && (
          <AccordionDetails>
            <Skeleton height={50} variant={"rect"} />
          </AccordionDetails>
        )}
        {faqs?.map((faq) => {
          return <Item key={faq.id} data={faq} />;
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default Category;
