import { sanitize } from "dompurify";
import { AxiosResponse } from "axios";
import { SharedApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { OptionsRequestDto, OptionsResponseDto } from "./dtos";

type Data = {
  application_example: string;
  login_screen_advantages: {
    header: string;
    description: string;
    icon: string;
  }[];
  address: string;
  phones: string[];
  dashboard_title: string;
  login_screen_header: string;
  logo_dark: string;
  logo_light: string;
  service_terms: number | false;
};

const responseToData = ({ data }: AxiosResponse<OptionsResponseDto>): Data => ({
  application_example: data.acf.application_example,
  login_screen_advantages: data.acf.login_screen_advatages,
  address: data.acf.contacts.address,
  phones: data.acf.contacts.phones.map(({ phone }) => phone),
  dashboard_title: sanitize(data.acf.dashboard_title),
  login_screen_header: data.acf.login_screen_header,
  logo_dark: data.acf.logo_dark,
  logo_light: data.acf.logo_light,
  service_terms: data.acf.service_terms,
});

export const OptionsEndpoint = new EndpointRequest<
  OptionsRequestDto,
  OptionsResponseDto,
  Data
>("OPTIONS", SharedApi.options, responseToData);
