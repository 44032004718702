import React, { ReactElement } from "react";
import isDate from "lodash/isDate";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { useStore } from "effector-react";
import { $currentLocale } from "../../store/currentLocale";

function Time(props: {
  value: string;
  valueFormat: string;
  parseFormat: string;
}): ReactElement<any, any> | null;

function Time(props: {
  value: Date;
  valueFormat: string;
}): ReactElement<any, any> | null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Time({ value, valueFormat, parseFormat }: any): any {
  const currentLocale = useStore($currentLocale);

  const date = isDate(value) ? value : parse(value, parseFormat, new Date());

  return (
    <>{format(date, valueFormat, { locale: currentLocale.dateFnsLocale })}</>
  );
}

export default Time;
