import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";
import stl from "./index.module.scss";
import { ProductCardProps } from "./index";

export type ProductCardSkeletonProps = Omit<ProductCardProps, "data">;
const ProductCardSkeleton: FC<ProductCardSkeletonProps> = () => {
  return (
    <div className={cn(stl.block)}>
      <span className={stl.imgWrap}>
        <Skeleton
          variant="rect"
          height={200}
          width={"100%"}
          animation="wave"
          style={{ display: "block" }}
        />
      </span>
      <Typography variant="h3" style={{ width: "80%" }}>
        <Skeleton variant="text" animation="wave" />
      </Typography>

      <Skeleton variant="text" animation="wave" width={"50%"} />

      <Skeleton
        variant="text"
        animation="wave"
        width={"80%"}
        style={{ marginBottom: 10 }}
      />

      <div className={stl.linkWrap}>
        <Skeleton
          variant="rect"
          height={50}
          animation="wave"
          style={{ borderRadius: "0 0 10px 10px" }}
        />
      </div>
    </div>
  );
};

export default ProductCardSkeleton;
