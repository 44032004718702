import ReactDOM from "react-dom";
import React, { FC, Suspense } from "react";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Providers from "../Providers";
import { Typography } from "@material-ui/core";

export const myProductChangelog = ({ content }: { content: string }): void => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const unmount = () => {
    const unmountResult = ReactDOM.unmountComponentAtNode(container);
    if (unmountResult && container.parentNode) {
      container.parentNode.removeChild(container);
    }
  };

  setTimeout(() => {
    ReactDOM.render(
      <Suspense fallback={<Loading />}>
        <MyProductChangelogModal onClose={unmount} content={content} />
      </Suspense>,
      container
    );
  });
};

const MyProductChangelogModal: FC<{ onClose: () => void; content: string }> = ({
  onClose,
  content,
}) => {
  const { t } = useTranslation(["productsModule"]);

  return (
    <Providers>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle id="form-dialog-title">
          <Typography variant={"h3"}>
            {t(`productsModule:myProduct.changelog`)}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </DialogContent>
      </Dialog>
    </Providers>
  );
};
