import React, { FC, useEffect, useMemo } from "react";
import cn from "classnames";
import DefaultLayout from "../../../layouts/Default";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { ProductCategoryCard } from "../../../cards";
import Grid from "@material-ui/core/Grid";
import { ProductCategory } from "../../../endpoints/products/_models/ProductCategory.model";
import basicLayoutStl from "../../../assets/styles/basicLayoutSettings.module.scss";
import { ProductCategoriesListEndpoint } from "../../../endpoints/products/categoriesList";

const ProductList: FC = () => {
  const { t, i18n } = useTranslation(["navigation", "base"]);

  const title = t("navigation:productCategories");

  const { data: productCategories, pending } = useStore(
    ProductCategoriesListEndpoint.store
  );

  useEffect(() => {
    ProductCategoriesListEndpoint.call({ parent: "root" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const skeletonValue = new Array(3).fill(null);

  const displayValue = useMemo<(null | ProductCategory)[]>(() => {
    if (pending) return skeletonValue;

    if (!productCategories) return [];

    return productCategories;
  }, [pending, productCategories, skeletonValue]);

  return (
    <DefaultLayout bannerProps={{ title }}>
      <section
        className={cn(
          basicLayoutStl.section,
          basicLayoutStl.sectionOverlayBanner
        )}
      >
        <Grid container spacing={3}>
          {displayValue.map((product, idx) => {
            return (
              <Grid
                key={product?.name ?? idx}
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
              >
                {!product ? (
                  <ProductCategoryCard.Skeleton />
                ) : (
                  <ProductCategoryCard data={product} />
                )}
              </Grid>
            );
          })}
        </Grid>
      </section>
    </DefaultLayout>
  );
};

export default ProductList;
