import http from "../../shared/http";
import { AxiosResponse, CancelToken } from "axios";
import { localeSwitches, qs } from "../../utils";
import i18n from "../../shared/i18n";
import {
  MyProductsUpdatesListRequestDto,
  MyProductsUpdatesListResponseDto,
} from "./list/dtos";
import {
  SetMyProductUpdateReadRequestDto,
  SetMyProductUpdateReadResponseDto,
} from "./setRead/dtos";

import {
  UnsetMyProductUpdateReadRequestDto,
  UnsetMyProductUpdateReadResponseDto,
} from "./unsetRead/dtos";

const MyProductsUpdatesApi = {
  getProductsUpdates: async (
    _: MyProductsUpdatesListRequestDto,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<MyProductsUpdatesListResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.get<MyProductsUpdatesListResponseDto>(
      `/my-software-updates/get${query}`,
      {
        cancelToken: cancelToken,
      }
    );
  },
  setMyProductUpdateRead: async (
    { versionId, softwareId }: SetMyProductUpdateReadRequestDto,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<SetMyProductUpdateReadResponseDto>> => {
    const query = qs.stringify({
      software_id: softwareId,
      version_id: versionId,
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.post<SetMyProductUpdateReadResponseDto>(
      `/software/mark-as-read${query}`,
      {
        cancelToken: cancelToken,
      }
    );
  },
  unsetMyProductUpdateRead: async (
    { versionId, softwareId }: UnsetMyProductUpdateReadRequestDto,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<UnsetMyProductUpdateReadResponseDto>> => {
    const query = qs.stringify({
      software_id: softwareId,
      version_id: versionId,
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.post<UnsetMyProductUpdateReadResponseDto>(
      `/software/mark-as-read/undo${query}`,
      {
        cancelToken: cancelToken,
      }
    );
  },
};

export { MyProductsUpdatesApi };
