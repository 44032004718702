import { AuthApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import {
  ResetPasswordRequestDto,
  ResetPasswordResponseDto,
  SetPasswordRequestDto,
  SetPasswordResponseDto,
  ValidatePasswordCodeRequestDto,
  ValidatePasswordCodeResponseDto,
} from "./dtos";
import { createEvent, createStore } from "effector";

export const resetRecovery = createEvent("RESET_RECOVERY");

export const ResetPasswordRecovery = new EndpointRequest<
  ResetPasswordRequestDto,
  ResetPasswordResponseDto,
  { code?: "bad_request"; message: string; status: number }
>("RESET_PASSWORD", AuthApi.resetPassword, ({ data }) => ({
  code: data.code,
  message: data.message,
  status: data.data.status,
}));
ResetPasswordRecovery.store.reset(resetRecovery);

export const SetPasswordRecovery = new EndpointRequest<
  SetPasswordRequestDto,
  SetPasswordResponseDto,
  { code?: "bad_request"; message: string; status: number }
>("SET_PASSWORD", AuthApi.setPassword, ({ data }) => ({
  code: data.code,
  message: data.message,
  status: data.data.status,
}));
SetPasswordRecovery.store.reset(resetRecovery);

export const ValidatePasswordCodeEndpoint = new EndpointRequest<
  ValidatePasswordCodeRequestDto,
  ValidatePasswordCodeResponseDto,
  { code?: "bad_request"; message: string; status: number }
>("VALIDATE_PASSWORD", AuthApi.validatePasswordCode, ({ data }) => ({
  code: data.code,
  message: data.message,
  status: data.data.status,
}));
ValidatePasswordCodeEndpoint.store.reset(resetRecovery);

export const $recoveryEmail = createStore<string | null>(null);
$recoveryEmail.on(ResetPasswordRecovery.call, (s, { email }) => email);
$recoveryEmail.reset(resetRecovery);

export const $recoveryCode = createStore<string | null>(null);
$recoveryCode.on(ValidatePasswordCodeEndpoint.call, (s, { code }) => code);
$recoveryCode.reset(resetRecovery);
