import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, NavLink } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import * as yup from "yup";
import { AppConfig } from "../../../config";
import Loading from "../../../components/Loading";
import { callPop } from "../../../App";
import { recoveryRoute } from "../Recovery/route";
import { LoginEndpoint } from "../../../endpoints/auth/login";
import { useStore } from "effector-react";

const Authenticate: FC = () => {
  const { t } = useTranslation(["authModule_login", "ff"]);
  const history = useHistory();

  const { pending, error } = useStore(LoginEndpoint.store);

  const successMessage = t("successMessage");
  useEffect(() => {
    const sub = LoginEndpoint.call.doneData.watch(() => {
      callPop?.(successMessage, { variant: "success" });
      LoginEndpoint.reset(undefined);
      history.push("/");
    });
    return () => {
      sub.unsubscribe();
    };
  }, [successMessage, history]);

  const validationSchema = yup.object({
    username: yup.string().required(t("ff:common.er")).email(t("ff:email.ei")),
    password: yup
      .string()
      .required(t("ff:common.er"))
      .min(
        AppConfig.minPasswordLength,
        t("ff:common.emin", { v: AppConfig.minPasswordLength })
      ),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      LoginEndpoint.call(values);
      // login(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
      {pending && <Loading withSuperBg />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 style={{ margin: 0 }}>{t("authModule_login:Title")}</h1>
        </Grid>
        <Grid item sm={8} xs={12}>
          <p style={{ margin: 0 }}>{t("authModule_login:Subtitle")}</p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="email"
            label={t("authModule_login:username")}
            name={"username"}
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="password"
            label={t("authModule_login:password")}
            name={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <NavLink to={recoveryRoute.path}>
            {t("authModule_login:recovery")}
          </NavLink>
        </Grid>
        {error?.message && (
          <Grid item xs={12}>
            <Alert severity="error">
              <span dangerouslySetInnerHTML={{ __html: error.message }} />
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            style={{ marginTop: 15 }}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            disabled={pending}
          >
            {t("authModule_login:Button")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Authenticate;
