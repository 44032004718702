import React, { FC, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import DefaultLayout from "../../../layouts/Default";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { ProductListEndpoint } from "../../../endpoints/products/list";
import { ProductCard } from "../../../cards";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import basicLayoutStl from "../../../assets/styles/basicLayoutSettings.module.scss";
import Filters, { FiltersValue } from "./Filters";
import { qs } from "../../../utils";

const PER_PAGE = 12;

const ProductList: FC = () => {
  const { t, i18n } = useTranslation(["navigation", "base"]);
  const location = useLocation();

  const title = t("navigation:products");

  const { data: products, pending } = useStore(ProductListEndpoint.store);

  useEffect(() => {
    const parsed = qs.parse(location.search.substr(1)) as Record<
      "filter",
      FiltersValue
    >;
    if (parsed.filter) {
      setFilter(parsed.filter);
    }
  }, [location]);

  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<FiltersValue>(null);

  const pagination = useMemo<{ totalPages: number; totalItems: number }>(() => {
    return {
      totalPages: products?.pagination.totalPages ?? 0,
      totalItems: products?.pagination.totalItems ?? 0,
    };
  }, [products]);

  useEffect(() => {
    readProducts();
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, filter]);

  useEffect(() => {
    window.history.replaceState(null, "Filter change", qs.stringify(filter));
  }, [filter]);

  const readProducts = async (isLoadMore?: boolean): Promise<void> => {
    ProductListEndpoint.cancel();

    let pageNum = page;
    if (isLoadMore) {
      pageNum++;
      setPage(pageNum);
    }

    await ProductListEndpoint.call({
      _query: {
        page: pageNum,
        per_page: PER_PAGE,
        search: filter?.search ?? undefined,
        category: filter?.category || filter?.group || undefined,
      },
      _isLoadMore: isLoadMore,
    });
  };

  const skeletonValue = new Array(3).fill(null);

  return (
    <DefaultLayout bannerProps={{ title }}>
      <section
        className={cn(
          basicLayoutStl.section,
          basicLayoutStl.sectionOverlayBanner
        )}
        style={{ paddingBottom: 0 }}
      >
        <Filters value={filter} onChange={setFilter} />
      </section>

      <section className={basicLayoutStl.section}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={4} sm={6} xs={12}>
            <h5>
              {t("base:displayAmountOfTotal", {
                amount: products?.data.length ?? "0",
                total: pagination.totalItems + "",
              })}
            </h5>
          </Grid>
          <Grid item lg={3} md={4} sm={3} xs={6} />
          <Grid item lg={3} md={4} sm={3} xs={6}>
            {/*<Select*/}
            {/*  value={orderBy}*/}
            {/*  onChange={(e) => {*/}
            {/*    setOrderBy(e.target.value as OrderBy);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {orderByList.map(({ tKey, value }) => (*/}
            {/*    <MenuItem key={value} value={value}>*/}
            {/*      {t(`base:orderBy.${tKey}`)}*/}
            {/*    </MenuItem>*/}
            {/*  ))}*/}
            {/*</Select>*/}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {products?.data.map((product, idx) => {
            return (
              <Grid
                key={product?.name ?? idx}
                item
                lg={3}
                md={4}
                sm={6}
                xs={12}
              >
                <ProductCard data={product} />
              </Grid>
            );
          })}
          {pending &&
            skeletonValue.map((idx) => {
              return (
                <Grid key={idx} item lg={3} md={4} sm={6} xs={12}>
                  <ProductCard.Skeleton />{" "}
                </Grid>
              );
            })}
        </Grid>
        {page < pagination.totalPages && (
          <Grid container justify={"center"} style={{ marginTop: 50 }}>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                disabled={pending}
                onClick={() => {
                  readProducts(true);
                }}
              >
                {t("base:loadMore")}
              </Button>
            </Grid>
          </Grid>
        )}
      </section>
    </DefaultLayout>
  );
};

export default ProductList;
