import Component from "./index";

export const productListRoute = {
  path: "/products",
  exact: true,
  component: Component,
};

export enum ProductListRouteFilterParams {
  search = "search",
  parentCategory = "group",
  childCategory = "category",
}
