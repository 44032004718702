import { ProductsApi } from "../api";
import { EndpointRequest, EndpointState } from "../../_system/EndpointRequest";
import { ProductsListRequestDto, ProductsListResponseDto } from "./dtos";
import { ProductShort } from "../_models/Product.short.model";
import { AxiosResponse } from "axios";

type Data = {
  data: ProductShort[];
  pagination: {
    totalItems: number;
    totalPages: number;
  };
};
const responseToData = (
  response: AxiosResponse<ProductsListResponseDto>,
  requestParams: ProductsListRequestDto,
  prevState: EndpointState<Data>
): Data => {
  let prevData: ProductShort[] = [];

  if (requestParams._isLoadMore) {
    prevData = prevState.data?.data ?? [];
  }

  const { data, headers } = response;

  return {
    data: [...prevData, ...data.map((i) => new ProductShort(i))],
    pagination: {
      totalItems: headers["x-wp-total"],
      totalPages: headers["x-wp-totalpages"],
    },
  };
};

const ProductListEndpoint = new EndpointRequest<
  ProductsListRequestDto,
  ProductsListResponseDto,
  Data
>("PRODUCTS", ProductsApi.getProducts, responseToData);

export { ProductListEndpoint };
