import React, { FC } from "react";
import Image from "../../assets/icons/loading_signal.gif";
import stl from "./index.module.scss";
import cn from "classnames";

interface Props {
  size?: "small" | "medium" | "big";
  withSuperBg?: boolean;
}

const Loading: FC<Props> = ({ withSuperBg = false, size }) => {
  return (
    <div
      className={cn(stl.block, {
        [stl.withSuperBg]: withSuperBg,
      })}
    >
      <img
        className={stl.image}
        src={Image}
        alt="Wait up, pls, content is on the way :0"
      />
    </div>
  );
};

export default Loading;
