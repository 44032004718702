import React, { FC, ReactElement, useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/Default";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { ProductEndpoint } from "../../../endpoints/products";
import { useStore } from "effector-react";
import Carousel from "../../../components/Carousel";
import Related from "../../../components/Related";

import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Skeleton from "@material-ui/lab/Skeleton";

import stl from "./index.module.scss";
import { AppConfig } from "../../../config";
import Attachments from "./Attachments";
import { ProductCard, ProductModificationCard } from "../../../cards";
import { isArrayNotEmptyOrNull, toNumber } from "../../../utils";
import styledStl from "../../../assets/styles/styledHtmlContent.module.scss";
import basicLayoutStl from "../../../assets/styles/basicLayoutSettings.module.scss";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { cartApi } from "../../../store/cart";
import { callPop } from "../../../App";
import { applyForPrices } from "../../../popups/applyForPrices";

const tKey = AppConfig.translationNS.productsProduct;

enum TabsKeys {
  TechChars,
  Description,
  Modifications,
  Configuration,
  Software,
  FAQ,
}
// TODO: split tabs on components
const ProductPage: FC = () => {
  const { t } = useTranslation([tKey]);
  const { slug: productSlug } = useParams<{ slug: string }>();
  const { data: product, pending } = useStore(ProductEndpoint.store);

  const [currentTab, setCurrentTab] = useState<number>(TabsKeys.TechChars);
  const [quantity, setQuantity] = useState<number>(1);

  const anyTabContentPresent =
    product?.techCharacteristics?.length ||
    product?.description?.long ||
    product?.modifications?.length ||
    product?.bundledItems;

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    setCurrentTab(TabsKeys.TechChars);
    ProductEndpoint.call({ slug: productSlug }).then((data) => {
      if (data) {
        if (isArrayNotEmptyOrNull(data.modifications)) {
          setCurrentTab(TabsKeys.Modifications);
        }
      }
    });
  }, [productSlug]);

  useEffect(() => {
    if (!product) return;

    let nextTab: number | null = null;
    if (product.techCharacteristics) {
      nextTab = TabsKeys.TechChars;
    } else if (product.description.long) {
      nextTab = TabsKeys.Description;
    } else if (product.modifications) {
      nextTab = TabsKeys.Modifications;
    } else if (product.bundledItems) {
      nextTab = TabsKeys.Configuration;
    }

    if (nextTab !== null) setCurrentTab(nextTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const relatedProductsRender = (): ReactElement[] | undefined => {
    if (pending || !product) {
      return [
        <ProductCard.Skeleton key={1} />,
        <ProductCard.Skeleton key={2} />,
        <ProductCard.Skeleton key={3} />,
      ];
    }

    if (!product.related) {
      return undefined;
    }

    return product.related.map((relatedProduct) => {
      return <ProductCard key={relatedProduct.id} data={relatedProduct} />;
    });
  };

  const addToCart = () => {
    if (product) {
      cartApi.add({
        quantity: quantity,
        product: product,
      });

      callPop(
        <div>
          {t("base:addedToCartNotify", { count: quantity })}{" "}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              applyForPrices();
            }}
          >
            {t("base:applyForPricesModal.openCart")}
          </a>
        </div>,
        {
          variant: "success",
        }
      );
    }
  };

  return (
    <DefaultLayout bannerProps={{ title: product?.name ?? "", pending }}>
      <div
        className={cn(
          basicLayoutStl.section,
          basicLayoutStl.sectionOverlayBanner
        )}
      >
        <div className={stl.top}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className={stl.topLeft}>
                {pending ? (
                  <Carousel.Media.Skeleton />
                ) : (
                  <Carousel.Media product={product} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={stl.topRight}>
                <h2 className="like-h1">{t(`${tKey}:MainInfo`)}</h2>
                {pending ? (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width={"90%"} />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width={"20%"} />
                    <Skeleton variant="text" width={"50%"} />
                  </>
                ) : (
                  <div
                    className={styledStl.content}
                    dangerouslySetInnerHTML={{
                      __html:
                        (product?.description.gallery as string | undefined) ??
                        "",
                    }}
                  />
                )}

                {product?.code && (
                  <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    <Grid item>
                      <TextField
                        value={quantity}
                        onChange={(e) => {
                          setQuantity(toNumber(e.target.value));
                        }}
                        style={{ marginBottom: 0, width: 150 }}
                        inputMode={"numeric"}
                        InputProps={{ inputProps: { min: 1 } }}
                        type="number"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={addToCart}
                        style={{ height: "100%" }}
                      >
                        {t(`${tKey}:AddToCart`)}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {pending && <Skeleton variant={"rect"} width={200} height={50} />}
      {anyTabContentPresent && (
        <Tabs
          value={currentTab}
          onChange={(_, val: number) => {
            setCurrentTab(val);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {product?.techCharacteristics && (
            <Tab
              label={t(`${tKey}:tabs.TechChars.label`)}
              value={TabsKeys.TechChars}
            />
          )}
          {product?.description.long && (
            <Tab
              label={t(`${tKey}:tabs.Description.label`)}
              value={TabsKeys.Description}
            />
          )}
          {product?.modifications && (
            <Tab
              label={t(`${tKey}:tabs.Modifications.label`)}
              value={TabsKeys.Modifications}
            />
          )}
          {product?.bundledItems && (
            <Tab
              label={t(`${tKey}:tabs.Configuration.label`)}
              value={TabsKeys.Configuration}
            />
          )}
        </Tabs>
      )}

      {anyTabContentPresent && (
        <div
          style={{ marginTop: 0 }}
          className={cn(
            basicLayoutStl.section,
            basicLayoutStl.sectionAbsoluteBgWhite
          )}
        >
          <Grid container spacing={3}>
            <Grid item md={9} xs={12}>
              {pending && <Skeleton variant={"rect"} height={300} />}

              {product?.techCharacteristics && (
                <TabPanel value={currentTab} index={TabsKeys.TechChars}>
                  <h3 className="like-h1">
                    {t(`${tKey}:tabs.TechChars.Title`)}
                  </h3>
                  <div
                    className={stl.techCharacteristicsTabContent}
                    dangerouslySetInnerHTML={{
                      __html: product.techCharacteristics,
                    }}
                  />
                </TabPanel>
              )}
              {product?.description.long && (
                <TabPanel value={currentTab} index={TabsKeys.Description}>
                  <h3 className="like-h1">
                    {t(`${tKey}:tabs.Description.Title`)}
                  </h3>
                  <div
                    className={cn(stl.descriptionTabContent, styledStl.content)}
                    dangerouslySetInnerHTML={{
                      __html: product.description.long,
                    }}
                  />
                </TabPanel>
              )}
              {product?.modifications && (
                <TabPanel value={currentTab} index={TabsKeys.Modifications}>
                  <h3 className="like-h1">
                    {t(`${tKey}:tabs.Modifications.Title`)}
                  </h3>
                  <Grid container spacing={1}>
                    {product.modifications.map((mod) => {
                      return (
                        <Grid item xs={12} key={mod.title}>
                          <ProductModificationCard data={mod} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </TabPanel>
              )}
              {product?.bundledItems && (
                <TabPanel value={currentTab} index={TabsKeys.Configuration}>
                  {product.bundledItems.base && (
                    <>
                      <h3 className="like-h1">
                        {t(`${tKey}:tabs.Configuration.BaseTitle`)}
                      </h3>
                      <Grid container spacing={1}>
                        {product.bundledItems.base.map((product) => {
                          return (
                            <Grid key={product.name} item md={4} sm={6} xs={6}>
                              <ProductCard
                                data={product}
                                variant={"bordered"}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}
                  {product.bundledItems.additional && (
                    <>
                      <h3 className="like-h1">
                        {t(`${tKey}:tabs.Configuration.AdditionalTitle`)}
                      </h3>
                      {product.bundledItems.additional.map((group) => {
                        return (
                          <div
                            key={group.title}
                            style={{ marginBottom: "2em" }}
                          >
                            <h4 className="like-h2">{group.title}</h4>
                            <Grid container spacing={1}>
                              {group.items.map((product) => {
                                return (
                                  <Grid
                                    key={product.name}
                                    item
                                    md={4}
                                    sm={6}
                                    xs={6}
                                  >
                                    <ProductCard
                                      data={product}
                                      variant={"bordered"}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </div>
                        );
                      })}
                    </>
                  )}
                </TabPanel>
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <Attachments data={product?.attachments} pending={pending} />
            </Grid>
          </Grid>
        </div>
      )}

      <Related
        sizes={{
          xl: 4,
          lg: 3,
          md: 2,
          sm: 1,
        }}
        children={relatedProductsRender()}
      />
    </DefaultLayout>
  );
};

export default ProductPage;

const TabPanel: FC<{ value: number; index: number }> = (props) => {
  const { children, value, index, ...other } = props;

  const isActive = value === index;

  return (
    <div
      role="tabpanel"
      hidden={!isActive}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {isActive && children}
    </div>
  );
};
