import React, { FC, PropsWithChildren } from "react";
import TopDesktop from "./Top.desktop";
import TopMobile from "./Top.mobile";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

interface Props {
  scrollTop: number;
  variant?: "separate";
  desktopClassName?: PropsWithChildren<HTMLDivElement>["className"];
  mobileClassName?: PropsWithChildren<HTMLDivElement>["className"];
}
const Top: FC<Props> = ({
  scrollTop,
  variant,
  desktopClassName,
  mobileClassName,
}) => {
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  return md ? (
    <TopDesktop
      color={scrollTop < 50 ? "transparent" : undefined}
      variant={variant}
      className={desktopClassName}
    />
  ) : (
    <TopMobile
      color={scrollTop < 50 ? "transparent" : undefined}
      variant={variant}
      className={mobileClassName}
    />
  );
};

export default Top;
