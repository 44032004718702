import { sanitize } from "dompurify";
import { ProductsItemDto } from "../list/dtos";
import { ProductBundledItemDto, ProductRelatedProductDto } from "../one/dtos";
import { isPresent } from "../../../utils";

interface ProductRelatedProductDto__Full extends ProductRelatedProductDto {
  isRelatedProduct: true;
}

type PossibleDto =
  | ProductsItemDto
  | ProductBundledItemDto
  | ProductRelatedProductDto__Full;

function isBundledItem(value: PossibleDto): value is ProductBundledItemDto {
  return isPresent((value as ProductBundledItemDto).bundled_item_id);
}

function isRelatedProductItem(
  value: PossibleDto
): value is ProductRelatedProductDto__Full {
  return isPresent((value as ProductRelatedProductDto__Full).isRelatedProduct);
}

export class ProductShort {
  id = -1;
  name = "";
  slug = "";
  type = "";
  description: string | null = null;
  image: {
    id: number;
    src: string;
    name: string;
    alt: string;
  } | null;

  constructor(dto: PossibleDto) {
    if (isBundledItem(dto)) {
      this.id = dto.product_id ?? -1;
      this.name = dto.title ?? "";
      this.slug = dto.slug ?? "";
      this.description = dto.description ? sanitize(dto.description) : null;
      this.image = dto.thumbnail
        ? {
            id: -1,
            src: dto.thumbnail,
            name: dto.title ?? "",
            alt: dto.title ?? "",
          }
        : null;
    } else if (isRelatedProductItem(dto)) {
      this.id = dto.id ?? -1;
      this.name = dto.title ?? "";
      this.slug = dto.slug ?? "";
      this.description = dto.description ? sanitize(dto.description) : null;
      this.image = dto.thumbnail
        ? {
            id: -1,
            src: dto.thumbnail,
            name: dto.title ?? "",
            alt: dto.title ?? "",
          }
        : null;
    } else {
      this.id = dto.id ?? -1;
      this.name = dto.name ?? "";
      this.slug = dto.slug ?? "";
      this.description = dto.short_description
        ? sanitize(dto.short_description)
        : null;
      const image = dto.images && dto.images?.[0];
      this.image = image
        ? {
            id: image.id,
            src: image.src,
            name: image.name,
            alt: image.alt,
          }
        : null;
    }
  }
}
