import { AxiosResponse } from "axios";
import { PostsApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { PostRequestDto, PostResponseDto } from "./dtos";
import { Post } from "../_models/Post.model";

type Data = Post | null;
const responseToData = (response: AxiosResponse<PostResponseDto>): Data => {
  if (!response.data[0]) return null;
  return new Post(response.data[0]);
};

const PostEndpoint = new EndpointRequest<PostRequestDto, PostResponseDto, Data>(
  "PRODUCT",
  PostsApi.getPost,
  responseToData
);

export { PostEndpoint };
