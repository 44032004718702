import React, { FC, useEffect, useMemo, useState } from "react";
import Container from "@material-ui/core/Container";
import cn from "classnames";
import stl from "./index.module.scss";
import Header from "./Header";
import Footer from "./Footer";
import Banner, { BannerProps } from "../../components/Banner";

interface Props {
  variant?: "separate";
  mainBg?: "primary" | "secondary";
  bannerProps?: BannerProps;
  footerHidden?: boolean;
  ignoreContainer?: boolean;
  maxWidth?: "md" | "lg";
}
const Layout: FC<Props> = ({
  children,
  variant,
  mainBg = "primary",
  bannerProps,
  footerHidden,
  ignoreContainer,
  maxWidth,
}) => {
  const separate = variant === "separate";
  const [scrollTop, setScrollTop] = useState<number>(0);

  // works good when "window"(body) is main scroll-container
  // normally you would keep "window"(body) as main scroll-container to save performance on mobile(chrome, etc)
  // consider this in css layout
  const onPageScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    setScrollTop(top);
  };

  useEffect(() => {
    window.addEventListener("scroll", onPageScroll);
    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  const mainBgColor = useMemo(() => {
    if (mainBg === "primary") return "#F5F7F9";
    if (mainBg === "secondary") return "white";
  }, [mainBg]);

  return (
    <div className={cn(stl.layout, { [stl.separate]: separate })}>
      <Header
        scrollTop={scrollTop}
        variant={variant}
        desktopClassName={stl.topDesktop}
        mobileClassName={stl.topMobile}
      />
      <main style={{ backgroundColor: mainBgColor }} className={stl.main}>
        {bannerProps && <Banner className={stl.banner} {...bannerProps} />}

        <div className={stl.mainInner}>
          {!ignoreContainer ? (
            <Container maxWidth={maxWidth} fixed>
              {children ?? ""}
            </Container>
          ) : (
            children ?? ""
          )}
        </div>

        {!separate && !footerHidden && <Footer className={stl.footer} />}
      </main>
    </div>
  );
};

export default Layout;
