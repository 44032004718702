import http from "../../shared/http";
import { AxiosResponse } from "axios";
import { localeSwitches, qs } from "../../utils";
import i18n from "../../shared/i18n";
import { OptionsRequestDto, OptionsResponseDto } from "./options/dtos";
import { TermsRequestDto, TermsResponseDto } from "./terms/dtos";
import {
  ApplyForPricesRequestDto,
  ApplyForPricesResponseDto,
} from "./applyForPrices/dtos";

const SharedApi = {
  options: async ({
    _query,
  }: OptionsRequestDto): Promise<AxiosResponse<OptionsResponseDto>> => {
    const query = qs.stringify({
      ..._query,
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.get<OptionsResponseDto>(
      `/acf/v3/options/options${query}`,
      { params: { __doNotAddBearerToken: true } }
    );
  },
  terms: async ({
    _id,
  }: TermsRequestDto): Promise<AxiosResponse<TermsResponseDto>> => {
    return await http.get<TermsResponseDto>(`wp/v2/pages/${_id}`);
  },
  applyForPrices: async (
    data: ApplyForPricesRequestDto
  ): Promise<AxiosResponse<ApplyForPricesResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.post<ApplyForPricesResponseDto>(
      `opportunity/create${query}`,
      data
    );
  },
};

export { SharedApi };
