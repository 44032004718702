import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import SuccessImg from "../../assets/icons/success.svg";
import { Typography } from "@material-ui/core";

const StepSuccess: FC = () => {
  const { t } = useTranslation(["base"]);

  return (
    <div style={{ padding: "10em 5em 10em", textAlign: "center" }}>
      <div>
        <img src={SuccessImg} alt="" />
      </div>
      <Typography variant={"h2"}>
        {t("base:applyForPricesModal.steps.success.Title")}
      </Typography>
      <Typography variant={"h3"}>
        {t("base:applyForPricesModal.steps.success.Subtitle")}
      </Typography>
    </div>
  );
};

export default StepSuccess;
