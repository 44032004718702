import { sanitize } from "dompurify";
import { PostDto, PostsRelatedPostDto } from "../one/dtos";
import isString from "lodash/isString";
import parse from "date-fns/parse";
import { isPresent } from "../../../utils";

interface PostsRelatedPostDto__Full extends PostsRelatedPostDto {
  isRelatedPost: true;
}

type PossibleDto = PostDto | PostsRelatedPostDto__Full;

function isRelated(value: PossibleDto): value is PostsRelatedPostDto__Full {
  return isPresent((value as PostsRelatedPostDto__Full).isRelatedPost);
}

export class PostShort {
  id = -1;
  title = "";
  slug = "";
  excerpt: string | null;
  image: string | null;
  isSticky = false;
  date: Date | null;

  constructor(dto: PossibleDto) {
    if (isRelated(dto)) {
      this.id = dto.ID ?? -1;
      this.title = dto.post_title;
      this.slug = dto.post_name ?? "";
      this.excerpt = dto.post_excerpt ?? null;
      this.image = null; // wait for back to add it
      this.isSticky = false;
      this.date = dto.post_modified
        ? parse(dto.post_modified, "yyyy-MM-dd' 'HH:mm:ss", new Date())
        : null;
    } else {
      this.id = dto.id ?? -1;
      this.title = dto.title.rendered;
      this.slug = dto.slug ?? "";
      this.excerpt = dto.excerpt.rendered
        ? sanitize(dto.excerpt.rendered)
        : null;
      this.image = isString(dto.thumbnail) ? dto.thumbnail : null;
      this.isSticky = dto.sticky;
      this.date = dto.date
        ? parse(dto.date, "yyyy-MM-dd'T'HH:mm:ss", new Date())
        : null;
    }
  }
}
