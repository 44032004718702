import { useMemo, useState } from "react";

export default useLocalValue;

function useLocalValue<Value>(
  outerValue: Value,
  initialValue: Value,
  onChange?: (value: Value) => void
): [Value, (value: Value) => void];

function useLocalValue<OuterValue, InnerValue>(
  outerValue: OuterValue,
  initialValue: InnerValue,
  onChange?: (value: OuterValue) => void,
  transformations?: {
    outerToInner: (value: OuterValue) => InnerValue;
    innerToOuter: (value: InnerValue) => OuterValue;
  }
): [InnerValue, (value: InnerValue) => void];

function useLocalValue(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  outerValue: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  initialValue: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onChange: any,
  transformations?: {
    outerToInner: (value: any) => any;
    innerToOuter: (value: any) => any;
  }
): any {
  const [localValue, setLocalValue] = useState<any>(initialValue);

  const displayValue = useMemo(() => {
    if (outerValue) {
      return transformations?.outerToInner
        ? transformations.outerToInner(outerValue)
        : outerValue;
    }
    return localValue;
  }, [outerValue, localValue, transformations]);

  const onChanges = (v: any) => {
    setLocalValue(v);

    const outerValue = transformations?.innerToOuter
      ? transformations?.innerToOuter(v)
      : v;

    onChange?.(outerValue);
  };

  return [displayValue, onChanges];
}
