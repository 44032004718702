import React, { FC, useEffect, useMemo } from "react";
import cn from "classnames";
import DefaultLayout from "../../../layouts/Default";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { MyProductListEndpoint } from "../../../endpoints/myProducts";
import { MyProductCard } from "../../../cards";
import basicLayoutStl from "../../../assets/styles/basicLayoutSettings.module.scss";
import { MyProduct } from "../../../endpoints/myProducts/_models/MyProduct.model";

const Products: FC = () => {
  const { t, i18n } = useTranslation(["navigation", "base"]);

  const { data: products, pending } = useStore(MyProductListEndpoint.store);

  useEffect(() => {
    read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const read = async (): Promise<void> => {
    await MyProductListEndpoint.call({});
  };

  const skeletonValue = new Array(3).fill(null);

  const displayValue = useMemo<(null | MyProduct)[]>(() => {
    if (pending) return skeletonValue;

    return products ?? [];
  }, [pending, products, skeletonValue]);

  return (
    <DefaultLayout bannerProps={{ title: t("navigation:myProducts") }}>
      <section
        className={cn(
          basicLayoutStl.section,
          basicLayoutStl.sectionOverlayBanner
        )}
      >
        {displayValue.map((product, idx) => {
          return !product ? (
            <MyProductCard.Skeleton key={idx} />
          ) : (
            <MyProductCard
              key={product?._sku ?? idx}
              data={product}
              defaultExpanded={idx === 0}
            />
          );
        })}
      </section>
    </DefaultLayout>
  );
};

export default Products;
