import React, { FC, HTMLProps, ReactNode, MouseEvent } from "react";
import cn from "classnames";
import MuiModal, { ModalProps } from "@material-ui/core/Modal";
import stl from "./index.module.scss";
import Divider from "@material-ui/core/Divider";

interface Props extends Omit<ModalProps, "title"> {
  wrapProps?: HTMLProps<HTMLDivElement>;
  contentProps?: HTMLProps<HTMLDivElement>;
  closeProps?: HTMLProps<HTMLSpanElement>;
  width?: number;
  title?: string | ReactNode;
  prepend?: ReactNode;
  append?: ReactNode;
}

const Modal: FC<Props> = ({
  children,
  wrapProps,
  contentProps,
  closeProps,
  width = 600,
  title,
  prepend,
  append,
  ...props
}) => {
  const onClose = (e: MouseEvent) => {
    props.onClose?.(e, "backdropClick");
  };

  return (
    <MuiModal {...props} className={cn(stl.modal, props.className)}>
      <>
        <div
          {...wrapProps}
          className={cn(stl.block, wrapProps?.className)}
          style={{ width, ...wrapProps?.style }}
        >
          {prepend}
          <span
            {...closeProps}
            className={cn(stl.close, closeProps?.className)}
            onClick={onClose}
          >
            &times;
          </span>
          {title && (
            <>
              <span className={stl.title}>{title}</span>
              <Divider />
            </>
          )}
          <div
            {...contentProps}
            className={cn(stl.content, contentProps?.className)}
          >
            {children}
          </div>
          {append}
        </div>
      </>
    </MuiModal>
  );
};

export default Modal;
