import React, { FC } from "react";
import DefaultLayout from "../../layouts/Default";
import stl from "./index.module.scss";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import image from "../../assets/images/404.svg";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { NavLink } from "react-router-dom";
import { indexRoute } from "../../modules/Default/Index/route";

const PageNoContent: FC = () => {
  const { t } = useTranslation("base");

  return (
    <DefaultLayout footerHidden ignoreContainer>
      <div className={stl.page}>
        <Container fixed>
          <Grid container spacing={3} alignItems={"center"}>
            <Grid item md={6}>
              <img src={image} title="" />
            </Grid>
            <Grid item md={6}>
              <Typography variant={"h2"} className={stl.title}>
                {t("noContent.title")}
              </Typography>
              <Typography variant={"caption"} className={stl.text}>
                {t("noContent.text")}
              </Typography>
              <Button
                color={"secondary"}
                variant={"contained"}
                className={stl.btn}
                component={NavLink}
                to={indexRoute.path}
              >
                {t("noContent.btn")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </DefaultLayout>
  );
};

export default PageNoContent;
