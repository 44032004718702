import { sanitize } from "dompurify";
import { ProductCategoryDto } from "../categoriesList/dtos";

export class ProductCategory {
  id = -1;
  name = "";
  slug = "";
  type = "";
  description = "";
  image: {
    id: number;
    src: string;
    name: string;
    alt: string;
  } | null;

  constructor(dto: ProductCategoryDto) {
    this.id = dto.id ?? -1;
    this.name = dto.name ?? "";
    this.slug = dto.slug ?? "";
    this.description = dto.description ? sanitize(dto.description) : "";
    this.image = dto.image
      ? {
          id: dto.image.id,
          src: dto.image.src,
          name: dto.image.name,
          alt: dto.image.alt,
        }
      : null;
  }
}
