import React, { FC, HTMLProps } from "react";
import stl from "./index.module.scss";
import cn from "classnames";

interface Props extends HTMLProps<HTMLAnchorElement> {
  name: string;
  url: string;
  icon: string;
  subtype: string;
  filesize: string;
  variant?: "primary";
}
const DocumentCard: FC<Props> = ({
  name,
  url,
  icon,
  subtype,
  filesize,
  variant,
  ...props
}) => {
  return (
    <a
      {...props}
      className={cn(stl.card, props.className, {
        // [stl.default]: variant === "default",
        [stl.primary]: variant === "primary",
      })}
      href={url}
      target="_blank"
      rel="noreferrer"
      tabIndex={0}
    >
      <span className={stl.imgWrap}>
        <img src={icon} alt="" />
      </span>
      <span className={stl.content}>
        <h5>{name}</h5>
        <i>{filesize}</i>
      </span>
    </a>
  );
};

export default DocumentCard;
