import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import * as yup from "yup";
import { AppConfig } from "../../../config";
import Loading from "../../../components/Loading";
import { useStore } from "effector-react";
import {
  $recoveryCode,
  $recoveryEmail,
  SetPasswordRecovery,
} from "../../../endpoints/auth/recovery";

const PasswordForm: FC = () => {
  const { t } = useTranslation(["authModule_recovery", "ff"]);

  const { pending, error } = useStore(SetPasswordRecovery.store);
  const email = useStore($recoveryEmail);
  const code = useStore($recoveryCode);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t("ff:common.er"))
      .min(
        AppConfig.minPasswordLength,
        t("ff:common.emin", { v: AppConfig.minPasswordLength })
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (email && code) {
        SetPasswordRecovery.call({
          ...values,
          email,
          code,
        });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
      {pending && <Loading withSuperBg />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 style={{ margin: 0 }}>{t("step3.Title")}</h1>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="password"
            label={t("step3.password")}
            name={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
        {error?.message && (
          <Grid item xs={12}>
            <Alert severity="error">{error.message}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            style={{ marginTop: 15 }}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            disabled={pending}
          >
            {t("step3.Button")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordForm;
