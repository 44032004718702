import React, { FC } from "react";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { FiShoppingCart } from "react-icons/fi";
import { useStore } from "effector-react";
import { $cartItemsQuantity } from "../../../store/cart";
import { applyForPrices } from "../../../popups/applyForPrices";
import { callPop } from "../../../App";
import { useTranslation } from "react-i18next";

const Cart: FC = () => {
  const { t } = useTranslation();
  const cartQuantity = useStore($cartItemsQuantity);

  const handleOpen = () => {
    if (cartQuantity > 0) {
      applyForPrices();
    } else {
      callPop(t("applyForPricesModal.isCartEmpty"), { variant: "warning" });
    }
  };

  return (
    <Avatar
      style={{
        borderRadius: "30%",
        cursor: "pointer",
      }}
      onClick={handleOpen}
    >
      <Badge badgeContent={cartQuantity} color={"error"}>
        <FiShoppingCart />
      </Badge>
    </Avatar>
  );
};

export default Cart;
