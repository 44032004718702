import http from "../../shared/http";
import { AxiosResponse } from "axios";
import { LoginRequestDto, LoginResponseDto } from "./login/dtos";
import { RegisterRequestDto, RegisterResponseDto } from "./register/dtos";
import { MeResponseDto } from "./me/dtos";
import {
  ApplicationRequestDto,
  ApplicationResponseDto,
} from "./attachApplication/dtos";
import {
  ResetPasswordRequestDto,
  ResetPasswordResponseDto,
  ValidatePasswordCodeRequestDto,
  ValidatePasswordCodeResponseDto,
  SetPasswordRequestDto,
  SetPasswordResponseDto,
} from "./recovery/dtos";
import { qs, objToFormData, localeSwitches } from "../../utils";
import i18n from "../../shared/i18n";
import {
  ChangePasswordRequestDto,
  ChangePasswordResponseDto,
} from "./changePassword/dtos";

export const AuthApi = {
  me: async (): Promise<AxiosResponse<MeResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.post<MeResponseDto>(`/wp/v2/users/me${query}`);
  },
  login: async ({
    _query,
    ...payload
  }: LoginRequestDto): Promise<AxiosResponse<LoginResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
      ..._query,
    });
    return await http.post<LoginResponseDto>(
      `/jwt-auth/v1/token${query}`,
      payload,
      { params: { __doNotAddBearerToken: true } }
    );
  },
  changePassword: async ({
    password,
  }: ChangePasswordRequestDto): Promise<
    AxiosResponse<ChangePasswordResponseDto>
  > => {
    return await http.put<ChangePasswordResponseDto>(`/wp/v2/users/me`, {
      password,
    });
  },
  register: async ({
    _query,
    ...payload
  }: RegisterRequestDto): Promise<AxiosResponse<RegisterResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
      ..._query,
    });
    return await http.post<RegisterResponseDto>(
      `users/v1/register${query}`,
      payload
    );
  },
  application: async ({
    _query,
    ...payload
  }: ApplicationRequestDto): Promise<AxiosResponse<ApplicationResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
      ..._query,
    });
    const data = objToFormData(payload);
    return await http.post<ApplicationResponseDto>(
      `users/v1/apply${query}`,
      data
    );
  },
  resetPassword: async ({
    _query,
    ...payload
  }: ResetPasswordRequestDto): Promise<
    AxiosResponse<ResetPasswordResponseDto>
  > => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
      ..._query,
    });
    return await http.post<ResetPasswordResponseDto>(
      `bdpwr/v1/reset-password${query}`,
      payload
    );
  },
  validatePasswordCode: async ({
    _query,
    ...payload
  }: ValidatePasswordCodeRequestDto): Promise<
    AxiosResponse<ValidatePasswordCodeResponseDto>
  > => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
      ..._query,
    });
    return await http.post<ValidatePasswordCodeResponseDto>(
      `bdpwr/v1/validate-code${query}`,
      payload
    );
  },
  setPassword: async ({
    _query,
    ...payload
  }: SetPasswordRequestDto): Promise<AxiosResponse<SetPasswordResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
      ..._query,
    });
    return await http.post<SetPasswordResponseDto>(
      `bdpwr/v1/set-password${query}`,
      payload
    );
  },
};
