import { AxiosResponse } from "axios";
import { FaqApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { FaqCategoryDataRequestDto, FaqCategoryDataResponseDto } from "./dtos";
import { sanitize } from "dompurify";

export type FaqCategory = {
  id: number;
  title: string;
  text: string;
}[];
const responseToData = (
  response: AxiosResponse<FaqCategoryDataResponseDto>
): FaqCategory => {
  return response.data.map(({ id, title, content }) => {
    return {
      id: id,
      title: sanitize(title.rendered),
      text: sanitize(content.rendered),
    };
  });
};

const FaqCategoryEndpoint = new EndpointRequest<
  FaqCategoryDataRequestDto,
  FaqCategoryDataResponseDto,
  FaqCategory
>("FAQ_CATEGORY", FaqApi.getCategory, responseToData);

export { FaqCategoryEndpoint };
