import { AxiosResponse } from "axios";
import { SharedApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { TermsRequestDto, TermsResponseDto } from "./dtos";
import { humanizeFileSize } from "../../../utils";
import docImage from "../../../assets/images/doc.svg";
import pdfImage from "../../../assets/images/pdf.svg";

type Data = {
  title: string;
  content: string;
  aside: {
    title: string;
    id: number;
    content: {
      url: string;
      name: string;
      subtype: string;
      icon: string;
      filesize: string;
    }[];
  }[];
};
const responseToData = (v: AxiosResponse<TermsResponseDto>): Data => {
  const asideContent = v.data.acf.warranty_rules;

  const aside = asideContent.map((warrantyRule) => {
    let icon = warrantyRule.warranty_rules_service_file.icon;

    switch (warrantyRule.warranty_rules_service_file.subtype) {
      case "docx":
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "doc":
        icon = docImage;
        break;
      case "pdf":
        icon = pdfImage;
        break;
    }

    return {
      title: warrantyRule.warranty_rules_service_title,
      id: warrantyRule.warranty_rules_service_file.id,
      content: [
        {
          url: warrantyRule.warranty_rules_service_file.url,
          name: warrantyRule.warranty_rules_service_file.title,
          subtype: warrantyRule.warranty_rules_service_file.subtype,
          icon: icon,
          filesize: humanizeFileSize(
            warrantyRule.warranty_rules_service_file.filesize
          ),
        },
      ],
    };
  });

  return {
    title: v.data.title.rendered,
    content: v.data.content.rendered,
    aside,
  };
};

export const TermsAndConditionsEndpoint = new EndpointRequest<
  TermsRequestDto,
  TermsResponseDto,
  Data
>("TERMS_AND_CONDITIONS", SharedApi.terms, responseToData);
