import React, {
  FC,
  HTMLProps,
  JSXElementConstructor,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { useDropzone } from "react-dropzone";
import stl from "./index.module.scss";
import cn from "classnames";
import { FiFileText } from "react-icons/fi";
import isEqual from "lodash/isEqual";

// TODO: make this component super repeatable
interface Props extends Omit<HTMLProps<HTMLDivElement>, "onChange" | "value"> {
  value?: File[];
  onChange?: (v: File[]) => void;
  icon?: JSXElementConstructor<any>;
  btnText?: string;
  mode?: "single" | "multiple";
  disabled?: boolean;
}

const Upload: FC<Props> = ({
  value,
  onChange,
  icon: Icon = FiFileText,
  btnText = "Drop your files here",
  mode = "single",
  disabled = false,
  className,
  ...props
}) => {
  const [localValue, setLocalValue] = useState<File[]>([]);

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!isEqual(value, localValue)) {
      onChange?.(localValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  useEffect(() => {
    if (!isEqual(value, localValue)) {
      setLocalValue(value ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (disabled) return;
      setLocalValue(acceptedFiles);
    },
    [disabled]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ["image/*", "file/pdf"],
  });

  return (
    <div {...getRootProps()} className={cn(className, stl.block)} {...props}>
      <input {...getInputProps()} />
      <div className={stl.icon}>
        <Icon />
      </div>
      <button className={stl.button}>
        {localValue[0] ? localValue[0].name : btnText}
      </button>
      {isDragActive && !disabled && (
        <span className={stl.message}>Drop file here!</span>
      )}
    </div>
  );
};

export default Upload;
