import React, { FC, useState } from "react";
import { FaqCategory } from "../../../endpoints/faqCategories/one";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";

interface Props {
  data: FaqCategory[0];
}

const Item: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Accordion expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <AccordionSummary
        expandIcon={isOpen ? <FiMinusCircle /> : <FiPlusCircle />}
      >
        <Typography variant={"h4"}>{data.title}</Typography>
      </AccordionSummary>
      <AccordionDetails dangerouslySetInnerHTML={{ __html: data.text }} />
    </Accordion>
  );
};

export default Item;
