import { ComponentType, lazy, LazyExoticComponent } from "react";

function retry(
  fn: () => Promise<{ default: ComponentType<any> }>,
  retriesLeft = 5,
  interval = 1000
): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            window.location.reload();
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const retryImport = (
  importFn: Promise<{ default: ComponentType<any> }>
): LazyExoticComponent<any> => {
  return lazy(() => retry(() => importFn));
};
