import React, { FC, useState, MouseEvent } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";

import { useStore } from "effector-react";
import { MeEndpoint } from "../../../endpoints/auth/me";
import { useTranslation } from "react-i18next";
import { BsPerson } from "react-icons/bs";
import { logout } from "../../../store/shared";
import { changePassword } from "../../../popups/changePassword";

const User: FC = () => {
  const { t } = useTranslation();
  const { data } = useStore(MeEndpoint.store);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (!data) return null;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Avatar
        title={data.name}
        onClick={handleOpen}
        style={{ borderRadius: "30%", cursor: "pointer" }}
      >
        <BsPerson style={{ fontSize: "1.3em" }} />
      </Avatar>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            changePassword();
          }}
        >
          {t("changePasswordModal.Title")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          {t("logout")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default User;
