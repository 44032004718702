import { createEvent, createStore } from "effector";
import { Product } from "../endpoints/products/_models/Product.model";
import cloneDeep from "lodash/cloneDeep";

export interface CartItem {
  product: Product;
  quantity: number;
}

export type Cart = CartItem[];

const reset = createEvent();

export const $cart = createStore<Cart>([]).reset(reset);

export const $cartItemsQuantity = $cart.map((s) => {
  return s.reduce((acc, { quantity }) => acc + quantity, 0);
});

export const cartApi = {
  add: createEvent<CartItem>(),
  remove: createEvent<number>(),
  setQuantity: createEvent<{ id: number; quantity: number }>(),
  reset: reset,
};

$cart.on(cartApi.add, (s, v) => {
  const { product, quantity } = v;
  const clonedStore = cloneDeep(s);

  const existingItem = clonedStore.find(
    ({ product: { id } }) => id === product.id
  );
  if (existingItem) {
    existingItem.quantity += quantity;
  } else {
    clonedStore.push(v);
  }

  return clonedStore;
});

$cart.on(cartApi.remove, (s, id) => {
  const clonedStore = cloneDeep(s);

  const idx = clonedStore.findIndex(({ product }) => product.id === id);
  if (~idx) clonedStore.splice(idx, 1);

  return clonedStore;
});

$cart.on(cartApi.setQuantity, (s, { id, quantity }) => {
  const clonedStore = cloneDeep(s);

  const item = clonedStore.find(({ product }) => product.id === id);
  if (item) item.quantity = quantity;

  return clonedStore;
});
