import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import SuccessIcon from "../../../assets/icons/success.svg";

const Success: FC = () => {
  const { t } = useTranslation(["authModule_application"]);

  return (
    <div style={{ textAlign: "center" }}>
      <img src={SuccessIcon} alt="Success!" />
      <h2>{t("success.text")}</h2>
    </div>
  );
};

export default Success;
