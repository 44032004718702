import React, { FC, Suspense, useEffect, useMemo } from "react";
import Loading from "./components/Loading";
import { I18nextProvider } from "react-i18next";
import i18n from "./shared/i18n";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { createMuiTheme } from "@material-ui/core/styles";
import { AppConfig } from "./config";
import { useStore } from "effector-react";
import { MeEndpoint } from "./endpoints/auth/me";
import {
  getCurrentLocaleFromPath,
  getLocationPathBasename,
  replaceLocationPathBasename,
} from "./utils/location";

const defaultLocale = AppConfig.locales[0];

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2E4670",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#25D797",
      main: "#25D797",
      dark: "#25D797",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Inter",
    h1: {
      fontSize: "1.8em",
      lineHeight: "1.7",
      fontWeightBold: "500",
      htmlFontSize: "1.7em",
    },
    h2: {
      fontSize: "1.6em",
      lineHeight: "1.7",
      fontWeightBold: "500",
      htmlFontSize: "1.4em",
    },
    h3: {
      fontSize: "1.4em",
      lineHeight: "1.7",
      fontWeightBold: "500",
      htmlFontSize: "1.2em",
    },
    h4: {
      fontSize: "1.2em",
      lineHeight: "1.7",
      fontWeightBold: "500",
      htmlFontSize: "1.1em",
    },
    h5: {
      fontSize: "1em",
      lineHeight: "1.7",
      fontWeightBold: "500",
      htmlFontSize: "1em",
    },
  },
});

/**
 * This method reloads page with inserted desired language basename
 * @description I had to do it this way because of this https://github.com/ReactTraining/react-router/issues/5465
 * @description Don't try to make it better
 * @example /ru/login -> /en/login
 * @param {string} language
 */
export const changeLanguage = (language: string): void => {
  const currentLocale = getCurrentLocaleFromPath();

  const desiredLocale =
    AppConfig.locales.find(({ key }) => key === language) ?? defaultLocale;

  if (desiredLocale.key !== currentLocale?.key) {
    window.location.pathname = replaceLocationPathBasename(
      window.location.pathname,
      desiredLocale.urlBasename
    );
  }
};

/**
 * Most language operations are here
 */
export const LocalizedRouter: FC = ({ children }) => {
  const { data: me, pending: mePending } = useStore(MeEndpoint.store);

  /**
   * If user's default language is different that current - redirect to correct one.
   */
  useEffect(() => {
    const currentLocale = getCurrentLocaleFromPath();

    if (me?.defaultLanguage) {
      changeLanguage(me.defaultLanguage);
    } else {
      if (mePending) return;
      if (currentLocale) changeLanguage(currentLocale.key);
      else changeLanguage(defaultLocale.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.defaultLanguage]);

  /**
   * Is used for local(react-app) redirects
   * @type {string}
   */
  const basename = useMemo<string>(() => {
    const currentUrlBasename = getLocationPathBasename(
      window.location.pathname
    );
    const locale = AppConfig.locales.find(
      ({ key }) => key === currentUrlBasename
    );

    return locale?.urlBasename ?? defaultLocale.urlBasename;
  }, []);

  return <BrowserRouter basename={basename}>{children}</BrowserRouter>;
};

const Providers: FC = ({ children }) => {
  return (
    <Suspense fallback={Loading({})}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={4}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <LocalizedRouter>{children}</LocalizedRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default Providers;
