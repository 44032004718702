import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { MyProductCardProps } from "./index";
import Grid from "@material-ui/core/Grid";
import stl from "../MyProduct/index.module.scss";
import Typography from "@material-ui/core/Typography";

export type PostCardSkeletonProps = Omit<MyProductCardProps, "data">;
const ProductCardSkeleton: FC<PostCardSkeletonProps> = () => {
  return (
    <div className={stl.block}>
      <Grid container alignItems={"center"} spacing={3}>
        <Grid item lg={2} md={3}>
          <div className={stl.imgWrap}>
            <Skeleton
              variant="rect"
              height={150}
              width={"100%"}
              animation="wave"
              style={{ display: "block" }}
            />
          </div>
        </Grid>
        <Grid item lg={10} md={9}>
          <Typography variant="caption">
            <Skeleton variant="text" animation="wave" width={"10%"} />
          </Typography>
          <Typography variant="h3">
            <Skeleton variant="text" animation="wave" width={"30%"} />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductCardSkeleton;
