import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

const MediaCarouselSkeleton: FC = () => {
  return (
    <div>
      <Skeleton
        variant="rect"
        height={300}
        animation="wave"
        style={{ marginBottom: 20 }}
      />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Skeleton
            variant="rect"
            height={50}
            animation="wave"
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="rect"
            height={50}
            animation="wave"
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            variant="rect"
            height={50}
            animation="wave"
            style={{ marginBottom: 20 }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MediaCarouselSkeleton;
