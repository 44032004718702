import { MyProductsUpdatesApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import {
  MyProductsUpdatesListRequestDto,
  MyProductsUpdatesListResponseDto,
} from "./dtos";
import { AxiosResponse } from "axios";
import { MyProduct } from "../../myProducts/_models/MyProduct.model";

type Data = MyProduct[];
const responseToData = (
  response: AxiosResponse<MyProductsUpdatesListResponseDto>
): Data => {
  return response.data.data.map((data) => new MyProduct(data));
};

const MyProductsUpdatesListEndpoint = new EndpointRequest<
  MyProductsUpdatesListRequestDto,
  MyProductsUpdatesListResponseDto,
  Data
>("MY_PRODUCTS", MyProductsUpdatesApi.getProductsUpdates, responseToData);

export { MyProductsUpdatesListEndpoint };
