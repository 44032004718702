import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import cn from "classnames";
import { popupContainerClass } from "../../../shared/getPopupContainer";
import Hamburger from "../../../components/Hamburger";
import stl from "./Top.mobile.module.scss";
import Navigation from "./Navigation";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { useStore } from "effector-react";
import { OptionsEndpoint } from "../../../endpoints/shared";
import { $isAuthorized } from "../../../store/shared";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { authenticationRoute } from "../../../modules/Auth/Authenticate/route";
import { useTranslation } from "react-i18next";
import { $logo } from "../../../endpoints/shared";
import User from "./User";
import Cart from "./Cart";

interface Props {
  variant?: "separate";
  className?: PropsWithChildren<HTMLDivElement>["className"];
  color?: "transparent";
}
const TopMobile: FC<Props> = ({ className, variant, color }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const logo = useStore($logo);

  const { data } = useStore(OptionsEndpoint.store);

  const isAuthorized = useStore($isAuthorized);

  const separate = variant === "separate";

  const transparent = separate ? false : color === "transparent";

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    let on = true;
    if (md && on) close();
    return () => {
      on = false;
    };
  }, [md]);

  return (
    <header
      className={cn(
        className,
        stl.block,
        { [stl.transparent]: transparent },
        popupContainerClass
      )}
    >
      <div className={stl.topline}>
        <div className={stl.left}>
          <div>
            <Hamburger
              color={transparent ? "#FFFFFF" : undefined}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </div>
        </div>
        <div className={stl.center}>
          <NavLink to={"/"}>
            <img
              src={transparent ? logo?.light : logo?.dark}
              alt=""
              title={data?.login_screen_header}
            />
          </NavLink>
        </div>
        <div className={stl.right}>
          <div>
            <Cart />
          </div>
        </div>
      </div>

      <div className={cn(stl.popup, { [stl.open]: isOpen })}>
        <div className={stl.popupInner}>
          <img src={logo?.dark} alt="" title={data?.login_screen_header} />
          <User />
          <Navigation vertical />
          {!isAuthorized && separate && (
            <Button
              color="secondary"
              variant="contained"
              component={NavLink}
              to={authenticationRoute.path}
            >
              {t("authenticate")}
            </Button>
          )}
        </div>
      </div>
      <div
        className={cn(stl.overlay, { [stl.open]: isOpen })}
        onClick={close}
      />
    </header>
  );
};

export default TopMobile;
