import { AuthApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { ChangePasswordRequestDto, ChangePasswordResponseDto } from "./dtos";

const ChangePasswordEndpoint = new EndpointRequest<
  ChangePasswordRequestDto,
  ChangePasswordResponseDto,
  undefined
>("LOGIN", AuthApi.changePassword, () => undefined);

export { ChangePasswordEndpoint };
