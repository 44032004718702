import { createEvent, createStore } from "effector";
import { LoginEndpoint } from "../endpoints/auth/login";
import { RegisterEndpoint } from "../endpoints/auth/register";
import { MeEndpoint } from "../endpoints/auth/me";
import { history } from "../App";
import { authenticationRoute } from "../modules/Auth/Authenticate/route";

// EMAIL
export const $email = createStore<null | string>(null);
$email.on(LoginEndpoint.call.doneData, (_, { user_email }) => user_email);
$email.on(RegisterEndpoint.call, (_, { email }) => email);

// TOKEN
export const ACCESS_TOKEN_STORAGE_KEY = "access_token";

export const $token = createStore<null | string>(
  localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
);
const resetToken = createEvent();
$token.on(resetToken, () => null);

$token.on(LoginEndpoint.call.doneData, (_, { token }) => {
  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
  return token;
});

export const $isAuthorized = $token.map(Boolean);

export const logout = async (): Promise<void> => {
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  MeEndpoint.reset();
  resetToken();
  history.push(authenticationRoute.path);
};
