import React, { FC, ReactElement, useEffect } from "react";
import DefaultLayout from "../../../layouts/Default";
import { useParams } from "react-router-dom";
import { useStore } from "effector-react";
import styledStl from "../../../assets/styles/styledHtmlContent.module.scss";
import { PostEndpoint } from "../../../endpoints/posts";
import cn from "classnames";
import basicLayoutStl from "../../../assets/styles/basicLayoutSettings.module.scss";
import Related from "../../../components/Related";
import PostCard from "../../../cards/Post";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// const tKey = AppConfig.translationNS.productsProduct;

const PostPage: FC = () => {
  const { slug: postSlug } = useParams<{ slug: string }>();
  const { data: post, pending } = useStore(PostEndpoint.store);

  useEffect(() => {
    PostEndpoint.call({ slug: postSlug });
  }, [postSlug]);

  const relatedProductsRender = (): ReactElement[] | undefined => {
    if (pending || !post) {
      return [
        <PostCard.Skeleton key={1} />,
        <PostCard.Skeleton key={2} />,
        <PostCard.Skeleton key={3} />,
      ];
    }

    if (!post.related) {
      return undefined;
    }

    return post.related.map((relatedProduct) => {
      return <PostCard key={relatedProduct.id} data={relatedProduct} />;
    });
  };

  return (
    <DefaultLayout
      maxWidth="md"
      bannerProps={{ title: post?.title ?? "", pending }}
    >
      <div
        className={cn(
          basicLayoutStl.section,
          basicLayoutStl.sectionAbsoluteBgWhite
        )}
      >
        <div
          className={styledStl.content}
          dangerouslySetInnerHTML={{
            __html: (post?.content as string | undefined) ?? "",
          }}
        />
        {pending && <PostSkeleton />}
      </div>

      <Related
        sizes={{
          xl: 4,
          lg: 3,
          md: 2,
          sm: 1,
        }}
        children={relatedProductsRender()}
      />
    </DefaultLayout>
  );
};

export default PostPage;

const PostSkeleton: FC = () => {
  return (
    <>
      <Skeleton height={"20em"} variant={"rect"} style={{ marginTop: 0 }} />
      <Typography variant={"h1"}>
        <Skeleton />
      </Typography>

      <Skeleton width={"90%"} style={{ marginBottom: 12 }} />
      <Skeleton width={"50%"} style={{ marginBottom: 12 }} />
      <Skeleton width={"70%"} style={{ marginBottom: 12 }} />
      <Skeleton width={"80%"} style={{ marginBottom: 12 }} />
      <Skeleton width={"20%"} style={{ marginBottom: 12 }} />
    </>
  );
};
