import React, { FC } from "react";
import stl from "./Navigation.module.scss";
import cn from "classnames";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { termsAndConditionsRoute } from "../../../pages/TermsAndConditions/route";
import { postListRoute } from "../../../modules/Posts/PostList/route";
import { productCategoriesRoute } from "../../../modules/Default/ProductCategories/route";
import { faqRoute } from "../../../modules/FAQ/FAQList/route";
import { myProductsListRoute } from "../../../modules/MyProducts/MyProducts/route";

interface Props {
  vertical?: boolean;
}
const Navigation: FC<Props> = ({ vertical }) => {
  const { t } = useTranslation("navigation");

  return (
    <div className={cn(stl.links, { [stl.vertical]: vertical })}>
      <Link
        component={NavLink}
        color={"inherit"}
        to={productCategoriesRoute.path}
      >
        {t("products")}
      </Link>
      <Link component={NavLink} color={"inherit"} to={postListRoute.path}>
        {t("news")}
      </Link>
      <Link component={NavLink} color={"inherit"} to={faqRoute.path}>
        {t("faq")}
      </Link>
      <Link component={NavLink} color={"inherit"} to={myProductsListRoute.path}>
        {t("myProducts")}
      </Link>
      {/*<Link*/}
      {/*  component={NavLink}*/}
      {/*  color={"inherit"}*/}
      {/*  to={termsAndConditionsRoute.path}*/}
      {/*>*/}
      {/*  {t("terms")}*/}
      {/*</Link>*/}
    </div>
  );
};

export default Navigation;
