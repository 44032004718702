import { AuthApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { RegisterRequestDto, RegisterResponseDto } from "./dtos";

export const RegisterEndpoint = new EndpointRequest<
  RegisterRequestDto,
  RegisterResponseDto,
  { code: number; message: string; dontAttachApplication?: boolean }
>("REGISTER", AuthApi.register, (resData, reqData, prevState) => {
  return {
    ...resData.data,
    dontAttachApplication: reqData.country !== "UA",
  };
});
