import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import * as yup from "yup";
import Loading from "../../../components/Loading";
import { callPop } from "../../../App";
import { useHistory } from "react-router-dom";
import {
  applicationRoute,
  queryParam_isAttachCommitted,
} from "../Application/route";
import countries from "i18n-iso-countries";
import { localeSwitches, qs } from "../../../utils";
import { RegisterEndpoint } from "../../../endpoints/auth/register";
import { useStore } from "effector-react";

const Authenticate: FC = () => {
  const { t, i18n } = useTranslation(["authModule_registration", "ff"]);
  const history = useHistory();

  const [countryList, setCountryList] = useState<{ [k: string]: string }>({});

  const { pending, error } = useStore(RegisterEndpoint.store);

  useEffect(() => {
    const locale = localeSwitches.browserToServer(i18n.language);
    import(`i18n-iso-countries/langs/${locale}.json`).then((localeData) => {
      countries.registerLocale(localeData);
      setCountryList(
        countries.getNames(locale, {
          select: "official",
        })
      );
    });
  }, [i18n.language]);

  useEffect(() => {
    const sub = RegisterEndpoint.call.doneData.watch(
      ({ message, dontAttachApplication }) => {
        callPop?.(message, { variant: "success" });
        RegisterEndpoint.reset(undefined);
        if (dontAttachApplication) {
          const query = qs.stringify({ [queryParam_isAttachCommitted]: true });
          history.push(applicationRoute.path + query);
        }
        history.push(applicationRoute.path);
      }
    );
    return () => {
      sub.unsubscribe();
    };
  }, [history]);

  const validationSchema = yup.object({
    billing_first_name: yup.string().required(t("ff:common.er")),
    billing_last_name: yup.string().required(t("ff:common.er")),
    email: yup.string().required(t("ff:common.er")).email(t("ff:email.ei")),
    phone: yup.string().required(t("ff:common.er")),
    password: yup
      .string()
      .required(t("ff:common.er"))
      .min(8, "Minimum 8 glyphs"),
    country: yup.string().required(t("ff:common.er")),
    company: yup.string().required(t("ff:common.er")),
  });

  const formik = useFormik({
    initialValues: {
      billing_first_name: "",
      billing_last_name: "",
      email: "",
      phone: "",
      password: "",
      country: "",
      company: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      RegisterEndpoint.call(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
      {pending && <Loading withSuperBg />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 style={{ margin: 0 }}>{t("authModule_registration:Title")}</h1>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            label={t("authModule_registration:billing_first_name")}
            name={"billing_first_name"}
            value={formik.values.billing_first_name}
            onChange={formik.handleChange}
            error={
              formik.touched.billing_first_name &&
              Boolean(formik.errors.billing_first_name)
            }
            helperText={
              formik.touched.billing_first_name &&
              formik.errors.billing_first_name
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            label={t("authModule_registration:billing_last_name")}
            name={"billing_last_name"}
            value={formik.values.billing_last_name}
            onChange={formik.handleChange}
            error={
              formik.touched.billing_last_name &&
              Boolean(formik.errors.billing_last_name)
            }
            helperText={
              formik.touched.billing_last_name &&
              formik.errors.billing_last_name
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="email"
            label={t("authModule_registration:email")}
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text" // TODO: make masked
            label={t("authModule_registration:phone")}
            name={"phone"}
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="password"
            label={t("authModule_registration:password")}
            name={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel id="country-label">
            {t("authModule_registration:country")}
          </InputLabel>
          <Select
            labelId="country-label"
            variant="outlined"
            fullWidth
            label={t("authModule_registration:country")}
            name={"country"}
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            // helperText={formik.touched.country && formik.errors.country}
          >
            {Object.entries(countryList).map(([key, name]) => (
              <MenuItem key={key} value={key}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            label={t("authModule_registration:company")}
            name={"company"}
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
        </Grid>
        {error?.message && (
          <Grid item xs={12}>
            <Alert severity="error">{error.message}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            style={{ marginTop: 15 }}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            disabled={pending}
          >
            {t("authModule_registration:Button")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Authenticate;
