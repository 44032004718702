export const localeSwitches = {
  browserToServer: (key: string): string => {
    switch (key.toLowerCase()) {
      case "ua":
        return "uk";
    }
    return key;
  },
  serverToBrowser: (key: string): string => {
    switch (key.toLowerCase()) {
      case "uk":
        return "ua";
    }
    return key;
  },
};
