import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";

export interface Step2CommentProps {
  comment: string;
  setComment: (v: string) => void;
}

const Step2Comment: FC<Step2CommentProps> = ({ comment, setComment }) => {
  const { t } = useTranslation(["base"]);

  return (
    <>
      <h2>{t("base:applyForPricesModal.steps.comment.Title")}</h2>
      <TextField
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
    </>
  );
};

export default Step2Comment;
