import React, { FC, Suspense, useEffect } from "react";
import {
  Switch,
  Redirect,
  Route,
  RouteProps,
  useHistory,
} from "react-router-dom";
import Loading from "./components/Loading";
import stl from "./App.module.scss";
import { retryImport } from "./utils";
import { useSnackbar, ProviderContext } from "notistack";
import { useTranslation } from "react-i18next";
import { OptionsEndpoint } from "./endpoints/shared";
import { termsAndConditionsRoute } from "./pages/TermsAndConditions/route";
import CssBaseline from "@material-ui/core/CssBaseline";
import { History } from "history";
import { $isAuthorized, ACCESS_TOKEN_STORAGE_KEY } from "./store/shared";
import { authenticationRoute } from "./modules/Auth/Authenticate/route";

import defaultRoutes from "./modules/Default/routes";
import authRoutes from "./modules/Auth/routes";
import postsRoutes from "./modules/Posts/routes";
import faqRoutes from "./modules/FAQ/routes";
import myProductsRoutes from "./modules/MyProducts/routes";

import { setCurrentLocale } from "./store/currentLocale";
import { AppConfig } from "./config";
import { useStore } from "effector-react";
import { MeEndpoint } from "./endpoints/auth/me";
import { recoveryRoute } from "./modules/Auth/Recovery/route";
import Page404 from "./pages/NoContent";

import { hotjar } from "react-hotjar";

const DefaultModule = retryImport(import("./modules/Default"));
const AuthModule = retryImport(import("./modules/Auth"));
const PostsModule = retryImport(import("./modules/Posts"));
const FAQModule = retryImport(import("./modules/FAQ"));
const MyProductsModule = retryImport(import("./modules/MyProducts"));

const renderModule = (routes: RouteProps[], module: FC) => {
  return routes.map(({ path, exact }, idx) => (
    <Route
      key={path + " " + idx}
      path={path}
      exact={exact}
      component={module}
    />
  ));
};

export let callPop: ProviderContext["enqueueSnackbar"] = null as any; // it should be used only in App.tsx's children

// TODO: watch https://github.com/ReactTraining/history/issues/810 and use Router with basename & history
export let history: History = null as any; // it should be used only in App.tsx's children

const App: FC = () => {
  const isLoggedIn = useStore($isAuthorized);
  const { data: me, pending: mePending } = useStore(MeEndpoint.store);
  const localHistory = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useTranslation();

  useEffect(() => {
    hotjar.initialize(2802670, 6);

    history = localHistory;
    // remove in case opening app
    localHistory.listen(() => {
      document.documentElement.scrollTop = 0;
    });
    if (!localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)) {
      if (
        localHistory.location.pathname !== recoveryRoute.path &&
        localHistory.location.pathname !== termsAndConditionsRoute.path
      ) {
        localHistory.push(authenticationRoute.path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callPop = enqueueSnackbar;
  }, [enqueueSnackbar]);

  useEffect(() => {
    OptionsEndpoint.call({});

    const newLocale = AppConfig.locales.find(
      ({ key }) => key === i18n.language
    );
    if (newLocale) setCurrentLocale(newLocale);
  }, [i18n.language]);

  useEffect(() => {
    if (isLoggedIn) {
      MeEndpoint.call(undefined);
    }
  }, [isLoggedIn]);

  return (
    <div className={stl.page}>
      <CssBaseline />
      <Suspense fallback={Loading({})}>
        <Switch>
          <Redirect
            exact
            from={"/my-account/lost-password"}
            to={recoveryRoute.path}
          />

          {renderModule(authRoutes, AuthModule)}

          <Route
            path={termsAndConditionsRoute.path}
            exact={termsAndConditionsRoute.exact}
            component={termsAndConditionsRoute.component}
          />

          {!isLoggedIn && <Redirect to={authenticationRoute.path} />}

          {!mePending ? (
            <>
              {renderModule(defaultRoutes, DefaultModule)}
              {renderModule(postsRoutes, PostsModule)}
              {renderModule(faqRoutes, FAQModule)}
              {renderModule(myProductsRoutes, MyProductsModule)}
            </>
          ) : (
            <Route component={Loading} />
          )}

          <Route component={Page404} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;

if (process.env.NODE_ENV === "development") {
  document.addEventListener("keydown", (e) => {
    if (e.key === "F9") {
      // eslint-disable-next-line
      debugger;
    }
  });
}
