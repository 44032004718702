import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-xhr-backend";
import { AppConfig } from "../config";

const defaultLocaleBasename = window.location.pathname.split("/")[1];
const defaultLocale = AppConfig.locales.find(({ urlBasename }) => {
  return defaultLocaleBasename === urlBasename;
});

i18n
  .use(backend)
  .use(initReactI18next)
  .init(
    {
      backend: {
        loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      },
      lng: defaultLocale?.key ?? AppConfig.locales[0].key,
      fallbackLng: AppConfig.locales[0].key,
      supportedLngs: AppConfig.locales.map(({ key }) => key),

      ns: ["base"],
      defaultNS: "base",

      detection: {
        order: [
          // "path",
          "cookie",
          "localStorage",
          "sessionStorage",
          "navigator",
          "htmlTag",
          "subdomain",
        ],
      },

      debug: process.env.NODE_ENV === "development",

      react: { wait: true },
    },
    (err, t) => {
      if (err) {
        return console.log("Error while loading translation file: ", err);
      }
    }
  );

export default i18n;
