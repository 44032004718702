import { PostsApi } from "../api";
import { EndpointRequest, EndpointState } from "../../_system/EndpointRequest";
import { PostListRequestDto, PostListResponseDto } from "./dtos";
import { PostShort } from "../_models/Post.short.model";
import { AxiosResponse } from "axios";

type Data = {
  data: PostShort[];
  pagination: {
    totalItems: number;
    totalPages: number;
  };
};
const responseToData = (
  response: AxiosResponse<PostListResponseDto>,
  requestParams: PostListRequestDto,
  prevState: EndpointState<Data>
): Data => {
  let prevData: PostShort[] = [];

  if (requestParams._isLoadMore) {
    prevData = prevState.data?.data ? prevState.data.data : [];
  }

  const { data, headers } = response;

  return {
    data: [...prevData, ...data.map((i) => new PostShort(i))],
    pagination: {
      totalItems: headers["x-wp-total"],
      totalPages: headers["x-wp-totalpages"],
    },
  };
};

const PostListEndpoint = new EndpointRequest<
  PostListRequestDto,
  PostListResponseDto,
  Data
>("POSTS", PostsApi.getPostList, responseToData);

export { PostListEndpoint };
