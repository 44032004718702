import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import * as yup from "yup";
import Loading from "../../../components/Loading";
import { useStore } from "effector-react";
import { ResetPasswordRecovery } from "../../../endpoints/auth/recovery";

const EmailForm: FC = () => {
  const { t } = useTranslation(["authModule_recovery", "ff"]);

  const { error, pending } = useStore(ResetPasswordRecovery.store);

  const validationSchema = yup.object({
    email: yup.string().required(t("ff:common.er")).email(t("ff:email.ei")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      ResetPasswordRecovery.call(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
      {pending && <Loading withSuperBg />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 style={{ margin: 0 }}>{t("step1.Title")}</h1>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="email"
            label={t("step1.email")}
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        {error?.message && (
          <Grid item xs={12}>
            <Alert severity="error">{error.message}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            style={{ marginTop: 15 }}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            disabled={pending}
          >
            {t("step1.Button")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmailForm;
