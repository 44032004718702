import { Locale as DateFnsLocale } from "date-fns";
// ua
import dateFnsLocaleUK from "date-fns/locale/uk/index";
// ua
import dateFnsLocaleRU from "date-fns/locale/ru/index";
// en
import dateFnsLocaleEn from "date-fns/locale/en-GB/index";

interface IAppConfig {
  apiUrl: string;
  withAuthModule: boolean;
  minPasswordLength: number;
  locales: {
    title: string; // display text in select
    key: string; // name of translation folder in public/locales/
    /**
     * Basename in url
     * @example /en/login
     */
    urlBasename: string;
    flag: string; // take flag codes from https://github.com/madebybowtie/FlagKit/blob/master/Assets/Flags.md
    dateFnsLocale: DateFnsLocale;
  }[];
  translationNS: {
    auth: string;
    auth_application: string;
    auth_login: string;
    auth_recovery: string;
    auth_registration: string;
    base: string;
    ff: string;
    navigation: string;
    products: string;
    productsProduct: string;
  };
}

export const AppConfig: IAppConfig = {
  apiUrl: process.env.REACT_APP_DEV_API_URL ?? "api/",
  withAuthModule: true,
  minPasswordLength: 6,
  locales: [
    {
      title: "UA",
      key: "ua",
      urlBasename: "",
      flag: "UA",
      dateFnsLocale: dateFnsLocaleUK,
    },
    {
      title: "RU",
      key: "ru",
      urlBasename: "ru",
      flag: "RU",
      dateFnsLocale: dateFnsLocaleRU,
    },
    {
      title: "EN",
      key: "en",
      urlBasename: "en",
      flag: "GB",
      dateFnsLocale: dateFnsLocaleEn,
    },
  ],
  translationNS: {
    auth: "authModule",
    auth_application: "authModule_application",
    auth_login: "authModule_login",
    auth_recovery: "authModule_recovery",
    auth_registration: "authModule_registration",
    base: "base",
    ff: "ff",
    navigation: "navigation",
    products: "productsModule",
    productsProduct: "productsModule_product",
  },
};
