import React, { FC } from "react";
import { Product } from "../../../endpoints/products/_models/Product.model";
import stl from "./index.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import { DocumentCard } from "../../../cards";

interface Props {
  data?: Product["attachments"] | null;
  pending: boolean;
}

const Attachments: FC<Props> = ({ data, pending }) => {
  if (pending) {
    return (
      <div className={stl.attachmentsWrap}>
        <Skeleton variant={"rect"} style={{ marginBottom: 15 }} height={130} />
        <Skeleton variant={"rect"} style={{ marginBottom: 15 }} height={130} />
      </div>
    );
  }

  return (
    <div className={stl.attachmentsWrap}>
      {data?.map((item) => {
        return (
          <DocumentCard
            variant={"primary"}
            key={item.name + item.url}
            name={item.name}
            url={item.url}
            icon={item.icon}
            subtype={item.subtype}
            filesize={item.size}
            style={{ marginBottom: 15 }}
          />
        );
      })}
    </div>
  );
};

export default Attachments;
