import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import * as yup from "yup";
import Loading from "../../../components/Loading";
import { useStore } from "effector-react";
import {
  $recoveryEmail,
  ValidatePasswordCodeEndpoint,
} from "../../../endpoints/auth/recovery";

const CodeForm: FC = () => {
  const { t } = useTranslation(["authModule_recovery", "ff"]);

  const { pending, error } = useStore(ValidatePasswordCodeEndpoint.store);
  const email = useStore($recoveryEmail);

  const validationSchema = yup.object({
    code: yup.string().required(t("ff:common.er")),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (email) {
        ValidatePasswordCodeEndpoint.call({ ...values, email });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
      {pending && <Loading withSuperBg />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 style={{ margin: 0 }}>{t("step2.Title")}</h1>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            label={t("step2.code")}
            name={"code"}
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
          />
        </Grid>
        {error?.message && (
          <Grid item xs={12}>
            <Alert severity="error">{error.message}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            style={{ marginTop: 15 }}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            disabled={pending}
          >
            {t("step2.Button")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CodeForm;
