import { sanitize } from "dompurify";
import { ProductBundledItemDto, ProductDto } from "../one/dtos";
import docImage from "../../../assets/images/doc.svg";
import pdfImage from "../../../assets/images/pdf.svg";
import {
  htmlRmStyles,
  humanizeFileSize,
  isArrayNotEmptyOrNull,
  localeSwitches,
} from "../../../utils";
import { ProductShort } from "./Product.short.model";
import groupBy from "lodash/groupBy";

interface ProductImage {
  id: number;
  src: string;
  name: string;
  alt: string;
}

interface ProductVideo {
  id: number;
  src: string;
  icon: string;
  name: string;
  alt: string;
}

interface ProductAttachment {
  name: string;
  url: string;
  size: string;
  icon: string;
  subtype: string;
}

export interface ProductModification {
  description: string;
  thumbnail: string;
  title: string;
  slug: string;
  id: number;
}

export class Product {
  id = -1;
  name = "";
  slug = "";
  type = "";
  code: string | null = null;
  image: ProductImage | null;
  images: ProductImage[] = [];
  videos: ProductVideo[] = [];
  description: {
    gallery?: string | null;
    short?: string | null;
    long?: string | null;
  } = {};
  attachments: ProductAttachment[] = [];
  techCharacteristics: string | null = null;
  modifications: ProductModification[] | null = null;
  bundledItems: {
    base: ProductShort[] | null;
    additional:
      | {
          title: string;
          items: ProductShort[];
        }[]
      | null;
  } | null = null;
  related: ProductShort[] | null = null;
  translationsSlugs: { [key: string]: string } = {};
  categories: { slug: string; name: string; id: number }[] | null = null;

  constructor(dto: ProductDto) {
    this.id = dto.id ?? -1;
    this.name = dto.name ?? "";
    this.slug = dto.slug ?? "";
    this.code = dto.sku && dto.sku.length > 0 ? dto.sku : null;
    this.description = {
      gallery: dto.acf?.description_near_gallery
        ? sanitize(dto.acf.description_near_gallery)
        : null,
      long: dto.description ? sanitize(dto.description) : null,
      short: dto.short_description ? sanitize(dto.short_description) : null,
    };
    const image = dto.images ? dto.images?.[0] : null;
    this.image = image
      ? {
          id: image.id,
          src: image.src,
          name: image.name,
          alt: image.alt,
        }
      : null;
    this.images = dto.images
      ? dto.images.map((image) => {
          return {
            id: image.id,
            src: image.src,
            name: image.name,
            alt: image.alt,
          };
        })
      : [];

    this.videos = dto.acf?.videos
      ? dto.acf.videos?.map(({ videos_title, videos_file: video }) => {
          return {
            id: video.id,
            src: video.url,
            name: videos_title,
            alt: video.alt,
            icon: video.icon,
          };
        })
      : [];

    this.attachments = dto.acf?.attachments
      ? dto.acf?.attachments?.map((item) => {
          let icon = item.attachments_file.icon;

          switch (item.attachments_file.subtype) {
            case "docx":
            case "doc":
              icon = docImage;
              break;
            case "pdf":
              icon = pdfImage;
              break;
          }

          return {
            name: item.attachments_title,
            url: item.attachments_file.url,
            size: humanizeFileSize(item.attachments_file.filesize),
            icon: icon,
            subtype: item.attachments_file.subtype,
          };
        })
      : [];

    this.techCharacteristics = dto.acf?.product_tech_specs
      ? sanitize(htmlRmStyles(dto.acf.product_tech_specs))
      : null;

    this.modifications =
      isArrayNotEmptyOrNull(dto.modifications)?.map((mod) => {
        return {
          ...mod,
          description: sanitize(mod.description),
        };
      }) ?? null;

    const bundledItemsBase: ProductShort[] | null =
      dto.bundled_items
        ?.filter(({ optional }) => !optional)
        .map((data) => {
          return new ProductShort(data);
        }) ?? null;

    const bundledItemsAdditional: ProductBundledItemDto[] | null =
      dto.bundled_items?.filter(({ optional }) => optional) ?? null;

    const bundledItemsAdditionalGrouped = groupBy(
      bundledItemsAdditional,
      "category"
    );
    const bundledItemsAdditionalGroupedFormatted: {
      title: string;
      items: ProductShort[];
    }[] = Object.entries(bundledItemsAdditionalGrouped)
      .map(([title, items]) => ({
        title,
        items: items.map((item) => new ProductShort(item)),
      }))
      .filter(({ items }) => items.length > 0);

    const bundledItemsBaseValue = isArrayNotEmptyOrNull(bundledItemsBase);
    const bundledItemsAdditionalValue = isArrayNotEmptyOrNull(
      bundledItemsAdditionalGroupedFormatted
    );

    const isBundledItemsPresent =
      bundledItemsBaseValue || bundledItemsAdditionalValue;

    this.bundledItems = isBundledItemsPresent
      ? {
          base: isArrayNotEmptyOrNull(bundledItemsBase),
          additional: isArrayNotEmptyOrNull(
            bundledItemsAdditionalGroupedFormatted
          ),
        }
      : null;

    if (dto.related && dto.related.length > 0) {
      this.related = dto.related.map((data, idx) => {
        return new ProductShort({
          isRelatedProduct: true,
          ...data,
        });
      });
    }

    this.translationsSlugs = Object.entries(dto.translations_slug).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      return {
        ...acc,
        [localeSwitches.serverToBrowser(key)]: value,
      };
    }, {});

    this.categories = isArrayNotEmptyOrNull(dto.categories);
  }
}
