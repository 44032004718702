import React, { FC } from "react";
import stl from "./index.module.scss";
import { NavLink, NavLinkProps } from "react-router-dom";
import cn from "classnames";
import ProductCardSkeleton, { PostCardSkeletonProps } from "./Skeleton";
import styledStl from "../../assets/styles/styledHtmlContent.module.scss";
import { PostShort } from "../../endpoints/posts/_models/Post.short.model";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { GridSize } from "@material-ui/core/Grid";
import Time from "../../components/Time";
import { postRoute } from "../../modules/Posts/Post/route";

export const POST_CARD_SIZE_DEFAULT: Partial<Record<Breakpoint, GridSize>> = {
  lg: 3,
  md: 4,
  sm: 6,
  xs: 12,
};

export const POST_CARD_SIZE_STICKY: Partial<Record<Breakpoint, GridSize>> = {
  lg: 6,
  md: 8,
  sm: 12,
  xs: 12,
};

interface Props extends Omit<NavLinkProps, "to"> {
  data: PostShort;
  variant?: "sticky";
}
export type PostCardProps = Props;
const PostCard: FC<Props> & { Skeleton: FC<PostCardSkeletonProps> } = ({
  className,
  data,
  variant,
  ...props
}) => {
  // replace is hotfix, remove in refactor
  const route = `${postRoute.path.replace("/:slug", "")}/${data.slug}`;

  return (
    <NavLink
      {...props}
      to={route}
      className={cn(className, stl.block, {
        [stl.isSticky]: variant === "sticky",
      })}
    >
      {data.image && (
        <span className={stl.imgWrap}>
          <img src={data.image ?? undefined} alt="" />
        </span>
      )}
      <article className={stl.content}>
        <h3 className={stl.title}>{data.title}</h3>
        {data.excerpt && !data.image && (
          <span
            className={cn(stl.description, styledStl.content)}
            dangerouslySetInnerHTML={{ __html: data.excerpt }}
          />
        )}
        {data.date && (
          <time className={stl.date}>
            <Time value={data.date} valueFormat={"d MMMM yyyy"} />
          </time>
        )}
      </article>
    </NavLink>
  );
};

PostCard.Skeleton = ProductCardSkeleton;

export default PostCard;
