import { createStore } from "effector";
import { OptionsEndpoint } from "./options";

export * from "./options";
export * from "./terms";
export * from "./applyForPrices";

export const $logo = createStore<{ dark: string; light: string } | null>(null);
$logo.on(OptionsEndpoint.call.doneData, (_, { logo_light, logo_dark }) => ({
  dark: logo_dark,
  light: logo_light,
}));
