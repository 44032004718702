import React, { FC, HTMLProps, ReactElement, useMemo } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { AppConfig } from "../../config";
import Carousel from "../Carousel";
import stl from "./index.module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import basicLayoutStl from "../../assets/styles/basicLayoutSettings.module.scss";
import Typography from "@material-ui/core/Typography";
import { BsFillStarFill } from "react-icons/bs";

interface Props extends Omit<HTMLProps<HTMLDivElement>, "data" | "sizes"> {
  sizes: {
    xl: number;
    lg: number;
    md: number;
    sm: number;
  };
  children?: ReactElement[];
}

const Related: FC<Props> = ({ sizes, children, ...props }) => {
  const { t } = useTranslation(AppConfig.translationNS.base);

  const xl = useMediaQuery<Theme>((theme) => theme.breakpoints.up("xl"));
  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const sm = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));

  const displayProducts = useMemo<number>(() => {
    if (xl) return sizes.xl;
    if (lg) return sizes.lg;
    if (md) return sizes.md;
    if (sm) return sizes.sm;
    return 1;
  }, [xl, lg, md, sm, sizes]);

  if (!children || children.length === 0) return null;

  return (
    <div {...props} className={cn(basicLayoutStl.section, props.className)}>
      <Typography
        variant={"h3"}
        className="like-h1"
        style={{ marginBottom: "1em" }}
      >
        <span className={stl.star}>
          <BsFillStarFill />
        </span>
        {t("relatedProducts")}
      </Typography>
      <div className={stl.slidesContainer}>
        <Carousel
          slidesToScroll={1}
          slidesToShow={displayProducts}
          infinite={false}
          speed={500}
          dots={true}
        >
          {children.map((item) => {
            return (
              <div className={stl.slide} key={JSON.stringify(item.props)}>
                {item}
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Related;
