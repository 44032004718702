import http from "../../shared/http";
import { AxiosResponse, CancelToken } from "axios";
import { localeSwitches, qs } from "../../utils";
import i18n from "../../shared/i18n";
import { ProductsListRequestDto, ProductsListResponseDto } from "./list/dtos";
import { ProductRequestDto, ProductResponseDto } from "./one/dtos";
import {
  ProductCategoriesListRequestDto,
  ProductCategoriesListResponseDto,
} from "./categoriesList/dtos";

const ProductsApi = {
  getProducts: async (
    { _query }: ProductsListRequestDto,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<ProductsListResponseDto>> => {
    const query = qs.stringify({
      ..._query,
      stock_status: "instock",
      hide_empty: true,
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.get<ProductsListResponseDto>(`/wc/v3/products${query}`, {
      cancelToken: cancelToken,
    });
  },
  getProductsCategories: async (
    params: ProductCategoriesListRequestDto,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<ProductCategoriesListResponseDto>> => {
    const query = qs.stringify({
      parent: params.parent === "root" ? 0 : params.parent,
      hide_empty: true,
      lang: localeSwitches.browserToServer(i18n.language),
    });

    return await http.get<ProductCategoriesListResponseDto>(
      `/wc/v3/products/categories${query}`,
      { cancelToken: cancelToken }
    );
  },
  getProduct: async ({
    slug,
  }: ProductRequestDto): Promise<AxiosResponse<ProductResponseDto>> => {
    const query = qs.stringify({
      slug,
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.get<ProductResponseDto>(`/wc/v3/products/${query}`);
  },
};

export { ProductsApi };
