import { AxiosResponse } from "axios";
import { ProductsApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { ProductRequestDto, ProductResponseDto } from "./dtos";
import { Product } from "../_models/Product.model";

type Data = Product | null;
const responseToData = (response: AxiosResponse<ProductResponseDto>): Data => {
  if (!response.data[0]) return null;
  return new Product(response.data[0]);
};

const ProductEndpoint = new EndpointRequest<
  ProductRequestDto,
  ProductResponseDto,
  Data
>("PRODUCT", ProductsApi.getProduct, responseToData);

export { ProductEndpoint };
