import React, { FC, Fragment, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { Product } from "../../endpoints/products/_models/Product.model";
import { Cart, cartApi } from "../../store/cart";
import { CartProductCard } from "../../cards";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { useTranslation } from "react-i18next";

export interface Step1ProductListProps {
  cart: Cart;
  onClose: () => void;
}

const Step1ProductList: FC<Step1ProductListProps> = ({ cart, onClose }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (cart.length === 0) onClose();
  }, [cart, onClose]);

  return (
    <>
      <h2>{t("base:applyForPricesModal.steps.products.Title")}</h2>
      <Divider style={{ marginBottom: ".3em" }} />
      {cart.map(({ product, quantity }) => {
        return (
          <Fragment key={product.id}>
            <ProductItem product={product} quantity={quantity} />
            <Divider />
          </Fragment>
        );
      })}
      {cart.length === 0 && (
        <h3 style={{ textAlign: "center" }}>
          {t("applyForPricesModal.isCartEmpty")}
        </h3>
      )}
    </>
  );
};

export default Step1ProductList;

const ProductItem: FC<{ product: Product; quantity: number }> = ({
  product,
  quantity,
}) => {
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));

  const Component = md ? CartProductCard : CartProductCard.Mobile;

  return (
    <Component
      product={product}
      quantity={quantity}
      onRemove={cartApi.remove}
      onQuantityChange={cartApi.setQuantity}
    />
  );
};
