import React, { FC } from "react";
import stl from "./index.module.scss";
import { ProductShort } from "../../endpoints/products/_models/Product.short.model";
import { NavLink, NavLinkProps } from "react-router-dom";
import { productRoute } from "../../modules/Default/Product/route";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import ProductCardSkeleton, { ProductCardSkeletonProps } from "./Skeleton";
import styledStl from "../../assets/styles/styledHtmlContent.module.scss";

interface Props extends Omit<NavLinkProps, "to"> {
  data: ProductShort;
  variant?: "bordered";
}
export type ProductCardProps = Props;
const ProductCard: FC<Props> & { Skeleton: FC<ProductCardSkeletonProps> } = ({
  className,
  data,
  variant,
  ...props
}) => {
  const { t } = useTranslation();

  const route = `${productRoute.path.replace("/:slug", "")}/${data.slug}`;

  return (
    <NavLink
      {...props}
      to={route}
      className={cn(className, stl.block, {
        [stl.bordered]: variant === "bordered",
      })}
    >
      <span className={stl.imgWrap}>
        <img src={data.image?.src} alt="" title={data.image?.alt} />
      </span>
      <h3 className={stl.title}>{data.name}</h3>
      {data.description && (
        <span
          className={cn(stl.description, styledStl.content)}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      )}
      <span className={stl.linkWrap}>
        <span className={stl.link}>{t("details")}</span>
      </span>
    </NavLink>
  );
};

ProductCard.Skeleton = ProductCardSkeleton;

export default ProductCard;
