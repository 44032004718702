import React, { FC, useEffect, useState } from "react";
import Form from "./Form";
import stl from "./index.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { authenticationRoute } from "../Authenticate/route";
import { useTranslation } from "react-i18next";
import Success from "./Success";
import { AttachApplicationEndpoint } from "../../../endpoints/auth/attachApplication";
import { queryParam_isAttachCommitted } from "./route";
import { qs } from "../../../utils";

const Application: FC = () => {
  const { t } = useTranslation("authModule_application");
  const location = useLocation();
  const [isAttachCommitted, setIsAttachCommitted] = useState<boolean>(false);

  useEffect(() => {
    const search = qs.parse(location.search.substr(1));
    if (search[queryParam_isAttachCommitted]) {
      setIsAttachCommitted(true);
    }
  }, [location]);

  useEffect(() => {
    const sub = AttachApplicationEndpoint.call.doneData.watch(() => {
      setIsAttachCommitted(true);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    return () => {
      AttachApplicationEndpoint.reset(undefined);
    };
  }, []);

  return (
    <div className={stl.block}>
      <div className={stl.formWrap}>
        {!isAttachCommitted ? <Form /> : <Success />}
      </div>
      <p className={stl.option}>
        {t("option.text")}{" "}
        <NavLink to={authenticationRoute.path}>{t("option.link")}</NavLink>
      </p>
    </div>
  );
};

export default Application;
