import { ProductsApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import {
  ProductCategoriesListRequestDto,
  ProductCategoriesListResponseDto,
} from "./dtos";
import { ProductCategory } from "../_models/ProductCategory.model";
import { AxiosResponse } from "axios";

type Data = ProductCategory[];
const responseToData = (
  response: AxiosResponse<ProductCategoriesListResponseDto>
): Data => {
  const { data } = response;

  return data.map((i) => new ProductCategory(i));
};

const ProductCategoriesListEndpoint = new EndpointRequest<
  ProductCategoriesListRequestDto,
  ProductCategoriesListResponseDto,
  Data
>("PRODUCTS_CATEGORIES", ProductsApi.getProductsCategories, responseToData);

export { ProductCategoriesListEndpoint };
