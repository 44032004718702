import http from "../../shared/http";
import { AxiosResponse, CancelToken } from "axios";
import { localeSwitches, qs } from "../../utils";
import i18n from "../../shared/i18n";
import {
  FaqCategoriesListRequestDto,
  FaqCategoriesListResponseDto,
} from "./list/dtos";
import {
  FaqCategoryDataRequestDto,
  FaqCategoryDataResponseDto,
} from "./one/dtos";

const FaqApi = {
  getCategoriesList: async (
    params: FaqCategoriesListRequestDto,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<FaqCategoriesListResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.get<FaqCategoriesListResponseDto>(
      `/wp/v2/faq_category${query}`,
      {
        cancelToken: cancelToken,
      }
    );
  },
  getCategory: async ({
    id,
  }: FaqCategoryDataRequestDto): Promise<
    AxiosResponse<FaqCategoryDataResponseDto>
  > => {
    const query = qs.stringify({
      faq_category: id,
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.get<FaqCategoryDataResponseDto>(`/wp/v2/faq${query}`);
  },
};

export { FaqApi };
