import React, { FC, ReactElement, useEffect, useMemo } from "react";
import DefaultLayout from "../../../layouts/Default";
import { useStore } from "effector-react";
import { OptionsEndpoint } from "../../../endpoints/shared/options";
// import confetti from "canvas-confetti";
import { Button } from "@material-ui/core";
import { MyProductsUpdatesListEndpoint } from "../../../endpoints/myProductsUpdates/list";
import { MyProduct } from "../../../endpoints/myProducts/_models/MyProduct.model";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import basicLayoutStl from "../../../assets/styles/basicLayoutSettings.module.scss";
import { MyProductUpdatesCard, PostCard } from "../../../cards";
import Related from "../../../components/Related";
import { PostListEndpoint } from "../../../endpoints/posts/list";
import { NavLink } from "react-router-dom";
import { postListRoute } from "../../Posts/PostList/route";

// https://www.kirilv.com/canvas-confetti/
// const snow = () => {
//   const duration = 15 * 1000;
//   const animationEnd = Date.now() + duration;
//   let skew = 1;
//
//   function randomInRange(min: number, max: number) {
//     return Math.random() * (max - min) + min;
//   }
//
//   (function frame() {
//     const timeLeft = animationEnd - Date.now();
//     const ticks = Math.max(200, 500 * (timeLeft / duration));
//     skew = Math.max(0.8, skew - 0.001);
//
//     confetti({
//       particleCount: 1,
//       startVelocity: 0,
//       ticks: ticks,
//       origin: {
//         x: Math.random(),
//         // since particles fall down, skew start toward the top
//         y: Math.random() * skew - 0.2,
//       },
//       colors: ["#ffffff"],
//       shapes: ["circle"],
//       gravity: randomInRange(0.4, 0.6),
//       scalar: randomInRange(0.4, 1),
//       drift: randomInRange(-0.4, 0.4),
//     });
//
//     if (timeLeft > 0) {
//       requestAnimationFrame(frame);
//     }
//   })();
// };

const MainPage: FC = () => {
  const { data: options, pending: optionsPending } = useStore(
    OptionsEndpoint.store
  );

  const { i18n, t } = useTranslation(["navigation", "base"]);

  const { data: products, pending } = useStore(
    MyProductsUpdatesListEndpoint.store
  );
  const { data: relatedPosts, pending: relatedPostsPending } = useStore(
    PostListEndpoint.store
  );

  useEffect(() => {
    readUpdates();
    readRelatedPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const readUpdates = async (): Promise<void> => {
    await MyProductsUpdatesListEndpoint.call({});
  };

  const readRelatedPosts = async (): Promise<void> => {
    await PostListEndpoint.call({
      _query: { per_page: 100, page: 1, sticky: "true" },
    });
  };

  const skeletonValue = new Array(3).fill(null);

  const displayValue = useMemo<(null | MyProduct)[]>(() => {
    if (pending) return skeletonValue;

    return products ?? [];
  }, [pending, products, skeletonValue]);

  const relatedPostsRender = (): ReactElement[] | undefined => {
    if (relatedPostsPending || !relatedPosts) {
      return [
        <PostCard.Skeleton key={1} />,
        <PostCard.Skeleton key={2} />,
        <PostCard.Skeleton key={3} />,
      ];
    }

    return relatedPosts.data.map((relatedProduct) => {
      return <PostCard key={relatedProduct.id} data={relatedProduct} />;
    });
  };

  return (
    <DefaultLayout
      bannerProps={{
        title: options?.dashboard_title ?? "loading...",
        titleSize: "lg",
        pending: optionsPending,
      }}
    >
      <section
        className={cn(
          basicLayoutStl.section,
          basicLayoutStl.sectionOverlayBanner
        )}
      >
        {displayValue.map((product, idx) => {
          return !product ? (
            <MyProductUpdatesCard.Skeleton key={idx} />
          ) : (
            <MyProductUpdatesCard
              key={product?.name ?? idx}
              data={product}
              defaultExpanded={idx === 0}
            />
          );
        })}
      </section>

      <Related
        style={{ paddingBottom: "2em" }}
        sizes={{
          xl: 4,
          lg: 3,
          md: 2,
          sm: 1,
        }}
        children={relatedPostsRender()}
      />

      {!!relatedPosts?.data.length && (
        <div
          style={{ textAlign: "center", padding: "0 0 var(--bls-section-pb)" }}
        >
          <Button
            variant={"contained"}
            color={"secondary"}
            component={NavLink}
            to={postListRoute.path}
          >
            {t("base:allPosts")}
          </Button>
        </div>
      )}
    </DefaultLayout>
  );
};

export default MainPage;
