import ReactDOM from "react-dom";
import React, { FC, Suspense } from "react";
import Loading from "../components/Loading";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Providers from "../Providers";
import { Typography } from "@material-ui/core";

export const watchVideo = ({
  url,
  title,
  height,
}: {
  url: string;
  title: string;
  height?: number;
}): void => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const unmount = () => {
    const unmountResult = ReactDOM.unmountComponentAtNode(container);
    if (unmountResult && container.parentNode) {
      container.parentNode.removeChild(container);
    }
  };

  setTimeout(() => {
    ReactDOM.render(
      <Suspense fallback={<Loading />}>
        <MyProductChangelogModal
          onClose={unmount}
          url={url}
          title={title}
          height={height}
        />
      </Suspense>,
      container
    );
  });
};

const MyProductChangelogModal: FC<{
  onClose: () => void;
  url: string;
  title: string;
  height?: number;
}> = ({ onClose, url, title, height }) => {
  return (
    <Providers>
      <Dialog open={true} onClose={onClose} maxWidth={"lg"}>
        <DialogTitle id="form-dialog-title">
          <Typography variant={"h3"}>{title}</Typography>
        </DialogTitle>

        <DialogContent>
          <video
            style={{
              maxHeight: "70vh",
            }}
            title={title}
            controls={true}
            height={height ?? 400}
          >
            <source src={url} />
          </video>
        </DialogContent>
      </Dialog>
    </Providers>
  );
};
