import React, { FC, useEffect, useMemo } from "react";
import cn from "classnames";
import DefaultLayout from "../../../layouts/Default";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import {
  FaqCategoriesList,
  FaqCategoriesListEndpoint,
} from "../../../endpoints/faqCategories/list";
import Grid from "@material-ui/core/Grid";
import basicLayoutStl from "../../../assets/styles/basicLayoutSettings.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import Category from "./Category";

const Products: FC = () => {
  const { t, i18n } = useTranslation(["navigation", "base"]);

  const { data: groups, pending } = useStore(FaqCategoriesListEndpoint.store);

  useEffect(() => {
    read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const read = async (): Promise<void> => {
    await FaqCategoriesListEndpoint.call(undefined);
  };

  const skeletonValue = new Array(3).fill(null);

  const displayValue = useMemo<(null | FaqCategoriesList[0])[]>(() => {
    if (pending) return skeletonValue;

    if (!groups) return [];

    return groups;
  }, [pending, groups, skeletonValue]);

  return (
    <DefaultLayout
      bannerProps={{ title: t("navigation:faq") }}
      mainBg={"secondary"}
    >
      <section className={cn(basicLayoutStl.section)}>
        <Grid container spacing={3}>
          {displayValue.map((group, idx) => {
            return (
              <Grid key={group?.title ?? idx} item xs={12}>
                {!group ? (
                  <Skeleton height={60} variant={"rect"} />
                ) : (
                  <Category data={group} isFirst={idx === 0} />
                )}
              </Grid>
            );
          })}
        </Grid>
      </section>
    </DefaultLayout>
  );
};

export default Products;
