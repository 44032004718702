import React, { ChangeEvent, FC, useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ProductCategory } from "../../../endpoints/products/_models/ProductCategory.model";
import { ProductCategoriesListEndpoint } from "../../../endpoints/products/categoriesList";
import { ProductListRouteFilterParams } from "./route";
import stl from "./Filters.module.scss";
import { useDebounce } from "../../../utils";
import { useTranslation } from "react-i18next";
import { FiTrash, FiSearch } from "react-icons/fi";
import useLocalValue from "../../../hooks/useLocalValue";

type Value = Record<ProductListRouteFilterParams, string>;

export type FiltersValue = Value | undefined | null;

interface Props {
  value?: FiltersValue;
  onChange?: (data: FiltersValue) => void;
}

const Filters: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation(["productsModule"]);

  const [pending, setPending] = useState<boolean>(false);

  const emptyValue: Record<ProductListRouteFilterParams, string> = {
    [ProductListRouteFilterParams.search]: "",
    [ProductListRouteFilterParams.childCategory]: "",
    [ProductListRouteFilterParams.parentCategory]: "",
  };

  const [displayValue, onChanges] = useLocalValue(
    {
      ...emptyValue,
      ...value,
    },
    emptyValue,
    onChange
  );

  const [
    searchInputChangeEvent,
    setSearchInputChangeEvent,
  ] = useState<ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null>(
    null
  );

  const searchInputChangeEventDebounced = useDebounce(
    searchInputChangeEvent,
    1000
  );

  useEffect(() => {
    onFieldChange(
      ProductListRouteFilterParams.search,
      searchInputChangeEventDebounced?.target.value ?? ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputChangeEventDebounced]);

  const [parentCategories, setParentCategories] = useState<
    ProductCategory[] | null
  >(null);
  const [childCategories, setChildCategories] = useState<
    ProductCategory[] | null
  >(null);

  useEffect(() => {
    setPending(true);
    ProductCategoriesListEndpoint.call({ parent: "root" })
      .then(setParentCategories)
      .finally(() => setPending(false));
  }, []);

  const reset = () => {
    setSearchInputChangeEvent(null);
    onChanges(emptyValue);
  };

  const onFieldChange = (name: ProductListRouteFilterParams, v: string) => {
    onChanges({
      ...emptyValue,
      ...displayValue,
      [name]: v,
    });
  };

  useEffect(() => {
    if (displayValue?.[ProductListRouteFilterParams.parentCategory]) {
      setPending(true);
      ProductCategoriesListEndpoint.call({
        parent: displayValue[ProductListRouteFilterParams.parentCategory],
      })
        .then(setChildCategories)
        .finally(() => setPending(false));
    }
    onFieldChange(ProductListRouteFilterParams.childCategory, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayValue?.group]);

  return (
    <div className={stl.block}>
      <h2>{t("filter.title")}</h2>

      {pending && <Loading />}

      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <TextField
            className={stl.searchField}
            placeholder={t("filter.search")}
            value={searchInputChangeEvent?.target.value}
            onChange={setSearchInputChangeEvent}
            variant="outlined"
            fullWidth
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Select
            variant="outlined"
            fullWidth
            displayEmpty
            value={displayValue?.[ProductListRouteFilterParams.parentCategory]}
            onChange={(e) => {
              onFieldChange(
                ProductListRouteFilterParams.parentCategory,
                (e?.target.value as string) ?? ""
              );
            }}
          >
            <MenuItem key={""} value={""}>
              {t("filter.parentCategory")}
            </MenuItem>
            {parentCategories?.map((category) => {
              return (
                <MenuItem key={category.slug} value={category.id.toString()}>
                  {category.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Select
            variant="outlined"
            fullWidth
            displayEmpty
            value={displayValue?.[ProductListRouteFilterParams.childCategory]}
            onChange={(e) => {
              onFieldChange(
                ProductListRouteFilterParams.childCategory,
                (e?.target.value as string) ?? ""
              );
            }}
            disabled={
              !displayValue?.[ProductListRouteFilterParams.parentCategory] ||
              !childCategories ||
              childCategories.length === 0
            }
          >
            <MenuItem key={""} value={""}>
              {t("filter.childCategory")}
            </MenuItem>
            {childCategories?.map((category) => {
              return (
                <MenuItem key={category.slug} value={category.id.toString()}>
                  {category.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item md={2} sm={6} xs={12}>
          <Button
            startIcon={<FiTrash />}
            className={stl.btn}
            disabled={
              !displayValue?.search &&
              !displayValue?.group &&
              !displayValue?.category
            }
            onClick={reset}
            fullWidth
            color="default"
            variant="contained"
            type="submit"
          >
            {t("filter.clear")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Filters;
