import { AuthApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import { LoginRequestDto, LoginResponseDto } from "./dtos";

const LoginEndpoint = new EndpointRequest<
  LoginRequestDto,
  LoginResponseDto,
  LoginResponseDto
>("LOGIN", AuthApi.login);

export { LoginEndpoint };
