import React, { FC } from "react";
import Form from "./Form";
import stl from "./index.module.scss";
import { NavLink } from "react-router-dom";
import { authenticationRoute } from "../Authenticate/route";
import { useTranslation } from "react-i18next";

const Authenticate: FC = () => {
  const { t } = useTranslation("authModule_registration");
  return (
    <div className={stl.block}>
      <div className={stl.formWrap}>
        <Form />
      </div>
      <p className={stl.option}>
        {t("option.text")}{" "}
        <NavLink to={authenticationRoute.path}>{t("option.link")}</NavLink>
      </p>
    </div>
  );
};

export default Authenticate;
