import React from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import isString from "lodash/isString";
import { authenticationRoute } from "../modules/Auth/Authenticate/route";
import { callPop, history } from "../App";
import { sanitize } from "dompurify";
import { logout } from "../store/shared";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  baseURL: process.env.REACT_APP_DEV_API_URL,
});

// made it with promise usage to avoid some strange error with importing $token from store
const requestStart = async (request: AxiosRequestConfig): Promise<any> => {
  if (!request.params?.__doNotAddBearerToken) {
    const { $token } = await import("../store/shared");
    const token = $token.getState();
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
  } else {
    delete request.params.__doNotAddBearerToken;
  }
  return request;
};

const requestError = (error: any): Promise<any> => Promise.reject({ ...error });

axiosInstance.interceptors.request.use(requestStart, requestError);

const responseSuccess = (response: AxiosResponse): AxiosResponse => response;

const responseError = (error: AxiosError) => {
  const err = Promise.reject({ ...error });

  if (axios.isCancel(error)) return err;

  err.catch((err) => {
    const doErr = (message: string) => {
      callPop(
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(message),
          }}
        />,
        { variant: "error" }
      );
    };

    if (err.response) {
      const data = err.response.data;
      if (
        (err.response.status === 403 &&
          err.response.data.code === "jwt_auth_invalid_token") ||
        (err.response.data.status === 400 &&
          err.response.errors === "Unauthenticated.") ||
        err.response.status === 401
      ) {
        logout();
        return;
      }
      if (err.response.status === 401) {
        if (err.response.data.message) {
          doErr(err.response.data.message);
        }
      } else if (err.response.status === 500) {
        doErr(err.response.data.message);
      } /*else if (err.response.status === 404) {
        doErr({ message: "Not found!" });
      }*/ else if (
        err.response.data.message
      ) {
        doErr(data.message);
      } else if (
        err.response.data.errors &&
        isString(err.response.data.errors)
      ) {
        doErr(data.message);
      }
    } else {
      doErr(
        `Some error occured! This may happen if your Internet connection is down. Or if server is brawling.`
      );
    }
  });
  return err;
};

axiosInstance.interceptors.response.use(responseSuccess, responseError);

export default axiosInstance;
