import { RouteProps } from "react-router-dom";
import { indexRoute } from "./Index/route";
import { productListRoute } from "./ProductList/route";
import { productRoute } from "./Product/route";
import { productCategoriesRoute } from "./ProductCategories/route";

const routes: RouteProps[] = [
  indexRoute,
  productListRoute,
  productRoute,
  productCategoriesRoute,
];

export default routes;
