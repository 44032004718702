import { AxiosError } from "axios";
import isString from "lodash/isString";

// TODO: make filling for more fields: code, validation, etc.
export class EndpointError {
  raw: any;
  message: string | null = null;
  code: number | null = null;

  constructor(value: AxiosError) {
    this.raw = value;
    if (value.response && isString(value.response.data)) {
      this.message = value.response.data;
    }
    if (value.response?.data.message) {
      this.message = value.response.data.message;
    }
  }
}
