import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Loading from "../../../components/Loading";
import Upload from "../../../components/Upload";
import Alert from "@material-ui/lab/Alert";
import DownloadImg from "../../../assets/icons/download.svg";
import { useStore } from "effector-react";
import { AttachApplicationEndpoint } from "../../../endpoints/auth";
import { $email } from "../../../store/shared";
import { OptionsEndpoint } from "../../../endpoints/shared/options";

const Application: FC = () => {
  const { t } = useTranslation(["authModule_application"]);

  const email = useStore($email);
  const { data: optionsData } = useStore(OptionsEndpoint.store);
  const { pending } = useStore(AttachApplicationEndpoint.store);

  const [file, setFile] = useState<null | File>(null);

  const sendFile = () => {
    if (file && email) {
      AttachApplicationEndpoint.call({ email, application: file });
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {pending && <Loading withSuperBg />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 style={{ margin: 0 }}>{t("Title")}</h1>
        </Grid>
        <Grid item xs={12}>
          <p style={{ marginTop: 0 }}>{t("Subtitle")}</p>
        </Grid>
        {!email && (
          <Grid item xs={12}>
            <Alert severity="error" style={{ marginBottom: 30 }}>
              {t("noEmail")}
            </Alert>
          </Grid>
        )}
      </Grid>

      {email && (
        <Upload
          btnText={t("upload")}
          style={{ marginBottom: 15 }}
          disabled={pending || !email}
          onChange={(files) => {
            if (files[0]) setFile(files[0]);
          }}
        />
      )}

      <div>
        {optionsData && (
          <a
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#2F5AA8",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              fontSize: "1.1rem",
              marginBottom: 10,
            }}
            href={optionsData.application_example}
          >
            <img style={{ marginRight: 10 }} src={DownloadImg} alt="" />
            {t("example")}
          </a>
        )}
      </div>

      <Button
        style={{ marginTop: 15 }}
        color="secondary"
        variant="contained"
        disabled={pending || !email}
        onClick={sendFile}
      >
        {t("Button")}
      </Button>
    </div>
  );
};

export default Application;
