import http from "../../shared/http";
import { AxiosResponse, CancelToken } from "axios";
import { localeSwitches, qs } from "../../utils";
import i18n from "../../shared/i18n";
import {
  MyProductsListRequestDto,
  MyProductsListResponseDto,
} from "./list/dtos";

const MyProductsApi = {
  getProducts: async (
    _: MyProductsListRequestDto,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<MyProductsListResponseDto>> => {
    const query = qs.stringify({
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.get<MyProductsListResponseDto>(
      `/my-products/get${query}`,
      {
        cancelToken: cancelToken,
      }
    );
  },
};

export { MyProductsApi };
