import { FaqApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import {
  FaqCategoriesListRequestDto,
  FaqCategoriesListResponseDto,
} from "./dtos";
import { AxiosResponse } from "axios";

export type FaqCategoriesList = {
  id: number;
  title: string;
}[];

const responseToData = (
  response: AxiosResponse<FaqCategoriesListResponseDto>
): FaqCategoriesList => {
  return response.data.map(({ id, name }) => {
    return {
      id: id,
      title: name,
    };
  });
};

const FaqCategoriesListEndpoint = new EndpointRequest<
  FaqCategoriesListRequestDto,
  FaqCategoriesListResponseDto,
  FaqCategoriesList
>("FAQ_CATEGORIES", FaqApi.getCategoriesList, responseToData);

export { FaqCategoriesListEndpoint };
