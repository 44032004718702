import React, { FC, useState } from "react";
import stl from "./index.module.scss";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import Skeleton, { PostCardSkeletonProps } from "./Skeleton";
import { MyProduct } from "../../endpoints/myProducts/_models/MyProduct.model";
import Grid from "@material-ui/core/Grid";
import Accordion, { AccordionProps } from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { MdExpandMore } from "react-icons/md";
import { FiFileText, FiDownload, FiEyeOff, FiEye } from "react-icons/fi";
import { Button } from "@material-ui/core";
import { myProductChangelog } from "../../popups/myProductChangelog";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { useTranslation } from "react-i18next";
import { SetMyProductUpdateReadEndpoint } from "../../endpoints/myProductsUpdates/setRead";
import { UnsetMyProductUpdateReadEndpoint } from "../../endpoints/myProductsUpdates/unsetRead";
import { toNumber } from "../../utils";
import Loading from "../../components/Loading";
import { productRoute } from "../../modules/Default/Product/route";
import { callPop } from "../../App";

interface Props extends Omit<AccordionProps, "children"> {
  data: MyProduct;
  variantForSoftwareOnly?: boolean;
}

export type MyProductCardProps = Props;
const MyProductUpdatesCard: FC<Props> & {
  Skeleton: FC<PostCardSkeletonProps>;
} = ({ className, data, variant, ...props }) => {
  const { t } = useTranslation(["productsModule"]);

  const [isVisible, setIsVisible] = useState(true);
  const [pending, setPending] = useState(false);

  const setHidden = async (data: { softwareId: number; versionId: number }) => {
    if (!isVisible) return;
    setPending(true);

    await SetMyProductUpdateReadEndpoint.call(data);

    setIsVisible(false);
    setPending(false);

    callPop(
      <div style={{ maxWidth: 300 }}>
        {t("myProduct.cancelSetRead_Text")}
        <span
          className={"like-a"}
          style={{ color: "white" }}
          onClick={() => {
            setVisible(data);
          }}
        >
          {t("myProduct.cancelSetRead")}
        </span>
      </div>,
      { variant: "info" }
    );
  };

  const setVisible = async (data: {
    softwareId: number;
    versionId: number;
  }) => {
    setPending(true);
    await UnsetMyProductUpdateReadEndpoint.call(data);

    setIsVisible(true);
    setPending(false);
  };

  return (
    <Accordion {...props} className={cn(className, stl.block)}>
      <AccordionSummary expandIcon={<MdExpandMore />}>
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item lg={2} md={3}>
            <NavLink
              className={stl.imgWrap}
              to={`${productRoute.path.replace(":slug", "")}${data.slug}`}
            >
              <img src={data.image} alt="" />
            </NavLink>
          </Grid>
          <Grid item lg={10} md={9}>
            <Chip color={"default"} label={data.category} />

            <Typography variant="h3">
              <NavLink
                className={stl.productName}
                to={`${productRoute.path.replace(":slug", "")}${data.slug}`}
              >
                {data.name}
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div className={cn(stl.tabPanel, stl.product_variantForSoftwareOnly)}>
          {data.software?.map((softwareItem) => {
            const {
              id,
              filename,
              modalContent,
              fileurl,
              title,
              version,
            } = softwareItem;

            return (
              <div
                className={cn(stl.product, stl.product_variantForSoftwareOnly, {
                  [stl.product_hidden]: !isVisible,
                })}
                key={filename}
              >
                {pending && <Loading />}
                <Button
                  disabled={!isVisible}
                  className={stl.toggleReadBtn}
                  onClick={() => {
                    setHidden({
                      softwareId: id,
                      versionId: toNumber(version),
                    });
                  }}
                  title={
                    isVisible
                      ? t(`productsModule:myProduct.setRead`) +
                        ". " +
                        t(`productsModule:myProduct.setRead_Title`)
                      : t(`productsModule:myProduct.unsetRead`) +
                        ". " +
                        t(`productsModule:myProduct.unsetRead_Title`)
                  }
                >
                  {<FiEyeOff />}
                </Button>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item md={8}>
                    <Typography variant="h5" className={stl.name}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item md={4} className={stl.btnsWrap}>
                    {modalContent && (
                      <Button
                        className={cn(stl.btn, stl.btnChangelog)}
                        variant={"outlined"}
                        color={"default"}
                        onClick={() => {
                          myProductChangelog({ content: modalContent });
                        }}
                        disabled={!isVisible}
                        title={t(`productsModule:myProduct.changelog`)}
                      >
                        <FiFileText />
                      </Button>
                    )}
                    <Button
                      className={stl.btn}
                      variant={"outlined"}
                      color={"default"}
                      href={fileurl}
                      download
                      disabled={!isVisible}
                      startIcon={<FiDownload />}
                      title={t(
                        `productsModule:myProduct.tabs.Software.downloadSoftwareUpdate`
                      )}
                    >
                      {t(
                        `productsModule:myProduct.tabs.Software.downloadSoftwareUpdate`
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

MyProductUpdatesCard.Skeleton = Skeleton;

export default MyProductUpdatesCard;
