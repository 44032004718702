import React, { CSSProperties, FC, HTMLProps } from "react";
import stl from "./index.module.scss";
import cn from "classnames";

interface Props extends HTMLProps<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen?: (v: boolean) => void;
  color?: string;
}

const Hamburger: FC<Props> = ({
  isOpen,
  className,
  setIsOpen,
  color,
  ...props
}) => {
  return (
    <div
      style={{ "--c": color } as CSSProperties}
      onClick={setIsOpen ? () => setIsOpen(!isOpen) : undefined}
      className={cn(className, stl.block, { [stl.open]: isOpen })}
      {...props}
    >
      <div className={stl.inner} />
    </div>
  );
};

export default Hamburger;
