import React, { FC } from "react";
import stl from "./index.module.scss";
import { ProductCategory } from "../../endpoints/products/_models/ProductCategory.model";
import { NavLink, NavLinkProps } from "react-router-dom";
import cn from "classnames";
import ProductCardSkeleton, { ProductCardSkeletonProps } from "./Skeleton";
import styledStl from "../../assets/styles/styledHtmlContent.module.scss";
import {
  productListRoute,
  ProductListRouteFilterParams,
} from "../../modules/Default/ProductList/route";
import { qs } from "../../utils";

interface Props extends Omit<NavLinkProps, "to"> {
  data: ProductCategory;
}
export type ProductCardProps = Props;
const ProductCategoryCard: FC<Props> & {
  Skeleton: FC<ProductCardSkeletonProps>;
} = ({ className, data, ...props }) => {
  const route = `${productListRoute.path}${qs.stringify({
    filter: {
      [ProductListRouteFilterParams.parentCategory]: data.id,
    },
  })}`;

  return (
    <NavLink {...props} to={route} className={cn(className, stl.block)}>
      <span className={stl.imgWrap}>
        <img src={data.image?.src} alt="" title={data.image?.alt} />
      </span>
      <h3 className={cn(stl.title, "like-h2")}>{data.name}</h3>
      {data.description && (
        <span
          className={cn(stl.description, styledStl.content)}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      )}
    </NavLink>
  );
};

ProductCategoryCard.Skeleton = ProductCardSkeleton;

export default ProductCategoryCard;
