import http from "../../shared/http";
import { AxiosResponse } from "axios";
import { localeSwitches, qs } from "../../utils";
import i18n from "../../shared/i18n";
import { PostListRequestDto, PostListResponseDto } from "./list/dtos";
import { PostRequestDto, PostResponseDto } from "./one/dtos";

const PostsApi = {
  getPostList: async ({
    _query,
  }: PostListRequestDto): Promise<AxiosResponse<PostListResponseDto>> => {
    const query = qs.stringify({
      ..._query,
      lang: localeSwitches.browserToServer(i18n.language),
      ignore_sticky_posts: false,
    });
    return await http.get<PostListResponseDto>(`/wp/v2/posts${query}`);
  },
  getPost: async ({
    slug,
  }: PostRequestDto): Promise<AxiosResponse<PostResponseDto>> => {
    const query = qs.stringify({
      slug,
      lang: localeSwitches.browserToServer(i18n.language),
    });
    return await http.get<PostResponseDto>(`/wp/v2/posts${query}`);
  },
};

export { PostsApi };
