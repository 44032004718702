import React, { FC, HTMLProps } from "react";
import cn from "classnames";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import stl from "./index.module.scss";
import { useStore } from "effector-react";
import { $logo, OptionsEndpoint } from "../../../endpoints/shared";
import { NavLink } from "react-router-dom";
import { productListRoute } from "../../../modules/Default/ProductList/route";
import { termsAndConditionsRoute } from "../../../pages/TermsAndConditions/route";
import { useTranslation } from "react-i18next";
import { faqRoute } from "../../../modules/FAQ/FAQList/route";

const Footer: FC<HTMLProps<HTMLDivElement>> = ({ className, ...props }) => {
  const { t } = useTranslation("navigation");
  const logo = useStore($logo);
  const { data: options } = useStore(OptionsEndpoint.store);
  const nowYear = new Date().getFullYear();

  return (
    <footer className={cn(className, stl.block)} {...props}>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <NavLink to={"/"}>
              <img src={logo?.light} alt="" />
            </NavLink>
            <p style={{ opacity: 0.5 }}>Copyright © 1992-{nowYear}</p>
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            <h4>{t("base:footer.contacts")}</h4>
            <p>{options?.address}</p>
            {options?.phones.map((phone) => (
              <a key={phone} href={`tel:${phone}`}>
                {phone}
              </a>
            ))}
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <List>
              <ListItem disableGutters>
                <Link component={NavLink} to={productListRoute.path}>
                  {t("products")}
                </Link>
              </ListItem>
              {/*<ListItem disableGutters>*/}
              {/*  <Link component={NavLink} to={"/news"}>*/}
              {/*    {t("news")}*/}
              {/*  </Link>*/}
              {/*</ListItem>*/}
              <ListItem disableGutters>
                <Link component={NavLink} to={faqRoute.path}>
                  {t("faq")}
                </Link>
              </ListItem>
              <ListItem disableGutters>
                <Link component={NavLink} to={termsAndConditionsRoute.path}>
                  {t("terms")}
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
