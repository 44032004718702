import { MyProductsUpdatesApi } from "../api";
import { EndpointRequest } from "../../_system/EndpointRequest";
import {
  UnsetMyProductUpdateReadRequestDto,
  UnsetMyProductUpdateReadResponseDto,
} from "./dtos";
import { AxiosResponse } from "axios";

type Data = null;
const responseToData = (
  response: AxiosResponse<UnsetMyProductUpdateReadResponseDto>
): Data => {
  return null;
};

const UnsetMyProductUpdateReadEndpoint = new EndpointRequest<
  UnsetMyProductUpdateReadRequestDto,
  UnsetMyProductUpdateReadResponseDto,
  Data
>("MY_PRODUCTS", MyProductsUpdatesApi.unsetMyProductUpdateRead, responseToData);

export { UnsetMyProductUpdateReadEndpoint };
