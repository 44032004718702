import { sanitize } from "dompurify";
import { MyProductsItemDto } from "../list/dtos";
import { humanizeFileSize } from "../../../utils";

export class MyProduct {
  image: string;
  name: string;
  _sku: string;
  slug: string;
  category: string;

  software:
    | {
        id: number;
        modalContent: string;
        title: string;
        version: string;
        filetype: "pdf" | "zip" | string;
        fileurl: string;
        filesize: string;
        filename: string;
      }[]
    | null = null;

  documents:
    | {
        name: string;
        url: string;
        icon: string;
        subtype: string;
        filesize: string;
        filetype: "pdf" | "zip" | string;
      }[]
    | null = null;

  faq:
    | {
        groupTitle: string;
        items: {
          title: string;
          content: string;
        }[];
      }[]
    | null = null;

  videos:
    | {
        groupTitle: string;
        items: {
          title: string;
          height: number;
          fileurl: string;
          filesize: string;
          filename: string;
        }[];
      }[]
    | null = null;

  constructor(dto: MyProductsItemDto) {
    this.image = dto.thumbnail;
    this.name = dto.title;
    this.slug = dto.slug;
    this.category = dto.category;
    this._sku = dto._sku;

    this.software = dto.software
      ? dto.software.map((item) => {
          const { id, content, title, last_version_number, file } = item;
          return {
            id: id,
            modalContent: sanitize(content),
            title: title,
            version: last_version_number,
            fileurl: file.url,
            filesize: humanizeFileSize(file.filesize),
            filename: file.filename,
            filetype: file.subtype,
          };
        })
      : null;

    this.documents = dto.product_documents
      ? dto.product_documents.map(({ title, file }) => {
          return {
            name: title,
            url: file.url,
            icon: file.icon,
            subtype: file.subtype,
            filesize: humanizeFileSize(file.filesize),
            filetype: file.subtype,
          };
        })
      : null;

    this.faq = dto.product_faq
      ? Object.entries(dto.product_faq).map(([title, items]) => {
          return {
            groupTitle: sanitize(title),
            items: items,
          };
        })
      : null;

    this.videos = dto.product_videos
      ? Object.entries(dto.product_videos).map(([title, items]) => {
          return {
            groupTitle: sanitize(title),
            items: items.map(({ product_video, product_video_title }) => {
              return {
                title: product_video_title,
                height: product_video.height,
                fileurl: product_video.url,
                filesize: humanizeFileSize(product_video.filesize),
                filename: product_video.filename,
              };
            }),
          };
        })
      : null;
  }
}
