import React, { FC, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import DefaultLayout from "../../../layouts/Default";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { PostListEndpoint } from "../../../endpoints/posts/list";
import { PostCard } from "../../../cards";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { PostShort } from "../../../endpoints/posts/_models/Post.short.model";
import {
  POST_CARD_SIZE_DEFAULT,
  POST_CARD_SIZE_STICKY,
} from "../../../cards/Post";
import basicLayoutStl from "../../../assets/styles/basicLayoutSettings.module.scss";

const PER_PAGE = 12;

const Products: FC = () => {
  const { t, i18n } = useTranslation(["navigation", "base"]);

  const { data: posts, pending } = useStore(PostListEndpoint.store);

  const [page, setPage] = useState<number>(1);

  const pagination = useMemo<{ totalPages: number; totalItems: number }>(() => {
    return {
      totalPages: posts?.pagination.totalPages ?? 0,
      totalItems: posts?.pagination.totalItems ?? 0,
    };
  }, [posts]);

  useEffect(() => {
    read();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const read = async (isLoadMore?: boolean): Promise<void> => {
    let pageNum = page;
    if (isLoadMore) {
      pageNum++;
      setPage(pageNum);
    }

    await PostListEndpoint.call({
      _query: { page: pageNum, per_page: PER_PAGE },
      _isLoadMore: isLoadMore,
    });
  };

  const skeletonValue = new Array(3).fill(null);

  const displayValue = useMemo<(null | PostShort)[]>(() => {
    if (pending) return skeletonValue;

    if (!posts) return [];

    return posts?.data;
  }, [pending, posts, skeletonValue]);

  return (
    <DefaultLayout bannerProps={{ title: t("navigation:news") }}>
      <section
        className={cn(
          basicLayoutStl.section,
          basicLayoutStl.sectionOverlayBanner
        )}
      >
        <Grid container spacing={3}>
          {displayValue.map((product, idx) => {
            return (
              <Grid
                key={product?.title ?? idx}
                item
                {...(product?.isSticky
                  ? POST_CARD_SIZE_STICKY
                  : POST_CARD_SIZE_DEFAULT)}
              >
                {!product ? (
                  <PostCard.Skeleton />
                ) : (
                  <PostCard
                    data={product}
                    variant={product?.isSticky ? "sticky" : undefined}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
        {page < pagination.totalPages && (
          <Grid container justify={"center"} style={{ marginTop: 50 }}>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                disabled={pending}
                onClick={() => {
                  read(true);
                }}
              >
                {t("base:loadMore")}
              </Button>
            </Grid>
          </Grid>
        )}
      </section>
    </DefaultLayout>
  );
};

export default Products;
