import React, { FC, Suspense, useState } from "react";
import ReactDOM from "react-dom";
import cn from "classnames";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import Modal from "../../components/Modal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Providers from "../../Providers";
import { $cart, cartApi } from "../../store/cart";
import { history } from "../../App";
import Step1ProductList, { Step1ProductListProps } from "./step1_productList";
import Step2Comment, { Step2CommentProps } from "./step2_comment";
import StepSuccess from "./step_success";
import { ApplyForPricesEndpoint } from "../../endpoints/shared/applyForPrices";
import stl from "./index.module.scss";

const Steps: [
  { idx: number; slug: string; Component: FC<Step1ProductListProps> },
  {
    idx: number;
    slug: string;
    Component: FC<Step2CommentProps>;
  }
] = [
  {
    idx: 0,
    slug: "products",
    Component: Step1ProductList,
  },
  {
    idx: 1,
    slug: "comment",
    Component: Step2Comment,
  },
];

export const applyForPrices = (): void => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const unsubscribe = history.listen((_, action) => {
    if (action === "PUSH") {
      unmount();
    }
  });

  const unmount = () => {
    unsubscribe();
    const unmountResult = ReactDOM.unmountComponentAtNode(container);
    if (unmountResult && container.parentNode) {
      container.parentNode.removeChild(container);
    }
  };

  setTimeout(() => {
    ReactDOM.render(
      <Suspense fallback={<Loading />}>
        <ApplyForPricesModal onClose={unmount} />
      </Suspense>,
      container
    );
  });
};

const ApplyForPricesModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation(["base"]);
  const cart = useStore($cart);
  const { pending, error } = useStore(ApplyForPricesEndpoint.store);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  const nextStep = async () => {
    const nextStepIdx = Math.min(currentStep + 1, Steps.length - 1);
    setCurrentStep(nextStepIdx);
  };

  const prevStep = async () => {
    const nextStepIdx = Math.max(currentStep - 1, 0);
    setCurrentStep(nextStepIdx);
  };

  const send = async () => {
    await ApplyForPricesEndpoint.call({
      items: cart.map(({ product: { code }, quantity }) => ({
        item_code: code!,
        qty: quantity,
      })),
      to_discuss: comment,
    });
    setIsSuccessful(true);
    cartApi.reset();
  };

  const renderSteps = () => {
    if (isSuccessful) return <StepSuccess />;

    return Steps.map(({ idx, Component }) => {
      if (currentStep === idx) {
        return (
          <Component
            cart={cart}
            comment={comment}
            setComment={setComment}
            onClose={onClose}
          />
        );
      }
      return null;
    });
  };

  return (
    <Providers>
      <Modal
        prepend={
          <Stepper
            className={stl.steps}
            alternativeLabel
            nonLinear
            activeStep={currentStep}
          >
            {Steps.map(({ idx, slug }) => {
              return (
                <Step
                  key={idx}
                  onClick={() => setCurrentStep(idx)}
                  className={cn({ [stl.activeStep]: currentStep === idx })}
                >
                  <StepButton disabled={isSuccessful}>
                    {t(`base:applyForPricesModal.steps.${slug}.Title`)}
                  </StepButton>
                </Step>
              );
            })}
            <Step className={cn({ [stl.activeStep]: currentStep === 2 })}>
              <StepButton disabled>
                {t(`base:applyForPricesModal.steps.success.Title`)}
              </StepButton>
            </Step>
          </Stepper>
        }
        open={true}
        onClose={onClose}
        width={800}
        closeProps={{ className: stl.close }}
      >
        <>
          {pending && <Loading />}

          {renderSteps()}
          {error && <Alert color={"error"}>{error.message}</Alert>}

          {!isSuccessful && (
            <Grid style={{ marginTop: "1em" }} container spacing={2}>
              {currentStep !== 0 && (
                <Grid item>
                  <Button variant={"text"} size={"large"} onClick={prevStep}>
                    {t("base:applyForPricesModal.prevStep")}
                  </Button>
                </Grid>
              )}
              <Grid item style={{ marginLeft: "auto" }} />
              {currentStep !== Steps.length - 1 && (
                <Grid item>
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    size={"large"}
                    onClick={nextStep}
                  >
                    {t("base:applyForPricesModal.nextStep")}
                  </Button>
                </Grid>
              )}
              {currentStep === Steps.length - 1 && (
                <Grid item>
                  <Button
                    color={"secondary"}
                    variant={"contained"}
                    size={"large"}
                    onClick={send}
                  >
                    {t("base:applyForPricesModal.submit")}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </>
      </Modal>
    </Providers>
  );
};
