import React, { FC } from "react";
import { Product } from "../../endpoints/products/_models/Product.model";
import stl from "./productCard.module.scss";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { toNumber } from "../../utils";
import Button from "@material-ui/core/Button";
import { FiXCircle } from "react-icons/fi";
import { productRoute } from "../../modules/Default/Product/route";

interface Props {
  product: Product;
  quantity: number;
  onQuantityChange?: (v: { id: number; quantity: number }) => void;
  onRemove: (id: number) => void;
}

const CartProductDesktop: FC<Props> = ({
  product,
  quantity,
  onQuantityChange,
  onRemove,
}) => {
  const route = `${productRoute.path.replace("/:slug", "")}/${product.slug}`;

  const remove = () => {
    onRemove(product.id);
  };

  return (
    <Grid container spacing={2} className={stl.product} alignItems={"center"}>
      <Grid item xs={3}>
        <NavLink to={route} className={stl.imgWrap}>
          <img src={product.image?.src} alt="" />
        </NavLink>
      </Grid>
      <Grid item xs={onQuantityChange ? 6 : 7} className={stl.content}>
        {product.categories?.map(({ name }) => {
          return <Chip key={name} label={name} />;
        })}
        <NavLink to={route}>
          <Typography variant={"h3"} style={{ marginTop: ".5em" }}>
            {product.name}
          </Typography>
        </NavLink>
      </Grid>
      {onQuantityChange ? (
        <Grid item xs={2}>
          <TextField
            value={quantity}
            onChange={(e) => {
              onQuantityChange({
                id: product.id,
                quantity: toNumber(e.target.value),
              });
            }}
            style={{ marginBottom: 0, width: "100%" }}
            inputMode={"numeric"}
            InputProps={{ inputProps: { min: 1 } }}
            type="number"
            fullWidth
            variant="outlined"
          />
        </Grid>
      ) : (
        <Grid item xs={1}>
          x{quantity}
        </Grid>
      )}
      <Grid item xs={1}>
        <Button className={stl.close} onClick={remove}>
          <FiXCircle />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CartProductDesktop;
