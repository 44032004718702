import React, { FC, useEffect, useState, HTMLProps, Fragment } from "react";
import stl from "./index.module.scss";
import cn from "classnames";
import Skeleton, { PostCardSkeletonProps } from "./Skeleton";
import { MyProduct } from "../../endpoints/myProducts/_models/MyProduct.model";
import Grid from "@material-ui/core/Grid";
import Accordion, { AccordionProps } from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { FiFileText, FiDownload, FiPlusCircle } from "react-icons/fi";
import { MdExpandMore } from "react-icons/md";
import { Button } from "@material-ui/core";
import { myProductChangelog } from "../../popups/myProductChangelog";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { useTranslation } from "react-i18next";
import { watchVideo } from "../../popups/watchVideo";
import { productRoute } from "../../modules/Default/Product/route";
import { NavLink } from "react-router-dom";

enum TabsKeys {
  Software,
  Documents,
  FAQ,
  Videos,
}

interface Props extends Omit<AccordionProps, "children"> {
  data: MyProduct;
}
export type MyProductCardProps = Props;
const MyProductCard: FC<Props> & { Skeleton: FC<PostCardSkeletonProps> } = ({
  className,
  data,
  variant,
  ...props
}) => {
  const { t } = useTranslation(["productsModule"]);
  const [currentTab, setCurrentTab] = useState<number>(TabsKeys.Software);

  useEffect(() => {
    if (data.software) {
      setCurrentTab(TabsKeys.Software);
    } else if (data.documents) {
      setCurrentTab(TabsKeys.Documents);
    } else if (data.faq) {
      setCurrentTab(TabsKeys.FAQ);
    } else if (data.name) {
      setCurrentTab(TabsKeys.Videos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion {...props} className={cn(className, stl.block)}>
      <AccordionSummary expandIcon={<MdExpandMore />}>
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item lg={2} md={3}>
            <NavLink
              className={stl.imgWrap}
              to={`${productRoute.path.replace(":slug", "")}${data.slug}`}
            >
              <img src={data.image} alt="" />
            </NavLink>
          </Grid>
          <Grid item lg={10} md={9}>
            <Chip color={"default"} label={data.category} />

            <Typography variant="h3">
              <NavLink
                className={stl.productName}
                to={`${productRoute.path.replace(":slug", "")}${data.slug}`}
              >
                {data.name}
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Tabs
          className={stl.tabs}
          value={currentTab}
          onChange={(_, val: number) => {
            setCurrentTab(val);
          }}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor={"primary"}
        >
          {data.software && (
            <Tab
              className={stl.tab}
              label={t(`productsModule:myProduct.tabs.Software.tabName`)}
              value={TabsKeys.Software}
            />
          )}
          {data.documents && (
            <Tab
              className={stl.tab}
              label={t(`productsModule:myProduct.tabs.Documents.tabName`)}
              value={TabsKeys.Documents}
            />
          )}
          {data.faq && (
            <Tab
              className={stl.tab}
              label={t(`productsModule:myProduct.tabs.FAQ.tabName`)}
              value={TabsKeys.FAQ}
            />
          )}
          {data.videos && (
            <Tab
              className={stl.tab}
              label={t(`productsModule:myProduct.tabs.Videos.tabName`)}
              value={TabsKeys.Videos}
            />
          )}
        </Tabs>

        <TabPanel
          className={stl.tabPanel}
          index={TabsKeys.Software}
          value={currentTab}
        >
          <Typography variant="h3">
            {t(`productsModule:myProduct.tabs.Software.Title`)}
          </Typography>

          {data.software?.map((softwareItem) => {
            const {
              filename,
              modalContent,
              filesize,
              fileurl,
              title,
              version,
              filetype,
            } = softwareItem;

            return (
              <div className={stl.product} key={filename}>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item md={6}>
                    <Typography variant="h5" className={stl.name}>
                      {title}
                    </Typography>
                    <span className={stl.filename}>{filename}</span>
                  </Grid>
                  <Grid item md={1}>
                    <span className={stl.version}>{version}</span>
                  </Grid>
                  <Grid item md={1}>
                    <span className={stl.filesize}>{filesize}</span>
                  </Grid>
                  <Grid item md={4} className={stl.btnsWrap}>
                    {modalContent && (
                      <Button
                        className={cn(stl.btn, stl.btnChangelog)}
                        variant={"outlined"}
                        color={"default"}
                        onClick={() => {
                          myProductChangelog({ content: modalContent });
                        }}
                        title={t(`productsModule:myProduct.changelog`)}
                      >
                        <FiFileText />
                      </Button>
                    )}
                    <Button
                      className={stl.btn}
                      variant={"outlined"}
                      color={"default"}
                      href={fileurl}
                      download
                      {...(filetype === "pdf" ? { target: "_blank" } : {})}
                      startIcon={<FiDownload />}
                      title={t(
                        `productsModule:myProduct.tabs.Software.downloadSoftwareUpdate`
                      )}
                    >
                      {t(
                        `productsModule:myProduct.tabs.Software.downloadSoftwareUpdate`
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </TabPanel>

        <TabPanel
          className={stl.tabPanel}
          index={TabsKeys.Documents}
          value={currentTab}
        >
          {data.documents?.map((item) => {
            return (
              <div key={item.name} className={stl.document}>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item md={7}>
                    <Typography variant="h5" className={stl.name}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item md={1}>
                    <span className={stl.filesize}>{item.filesize}</span>
                  </Grid>
                  <Grid item md={4} className={stl.btnsWrap}>
                    <Button
                      className={stl.btn}
                      variant={"outlined"}
                      color={"default"}
                      href={item.url}
                      download
                      {...(item.filetype === "pdf" ? { target: "_blank" } : {})}
                      startIcon={<FiDownload />}
                      title={t(
                        `productsModule:myProduct.tabs.Documents.download`
                      )}
                    >
                      {t(`productsModule:myProduct.tabs.Documents.download`)}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </TabPanel>

        <TabPanel
          className={stl.tabPanel}
          index={TabsKeys.FAQ}
          value={currentTab}
        >
          {data.faq?.map(({ groupTitle, items }) => {
            return (
              <Fragment key={groupTitle}>
                <Typography variant={"h3"}>{groupTitle}</Typography>
                {items.map(({ title, content }) => (
                  <Accordion key={title}>
                    <AccordionSummary expandIcon={<FiPlusCircle />}>
                      <Typography variant={"h5"}>{title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{content}</AccordionDetails>
                  </Accordion>
                ))}
              </Fragment>
            );
          })}
        </TabPanel>

        <TabPanel
          className={stl.tabPanel}
          index={TabsKeys.Videos}
          value={currentTab}
        >
          {data.videos?.map(({ groupTitle, items }) => (
            <Fragment key={groupTitle}>
              <Typography variant={"h3"}>{groupTitle}</Typography>
              {items.map((item) => (
                <div key={item.title} className={stl.document}>
                  <Grid container spacing={3} alignItems={"center"}>
                    <Grid item md={7}>
                      <Typography variant="h5" className={stl.name}>
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item md={1}>
                      <span className={stl.filesize}>{item.filesize}</span>
                    </Grid>
                    <Grid item md={4} className={stl.btnsWrap}>
                      <Button
                        className={stl.btn}
                        variant={"outlined"}
                        color={"default"}
                        title={t(`productsModule:myProduct.tabs.Videos.watch`)}
                        onClick={() => {
                          watchVideo({
                            url: item.fileurl,
                            title: item.title,
                            height: item.height,
                          });
                        }}
                      >
                        {t(`productsModule:myProduct.tabs.Videos.watch`)}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Fragment>
          ))}
        </TabPanel>
      </AccordionDetails>
    </Accordion>
  );
};

MyProductCard.Skeleton = Skeleton;

const TabPanel: FC<
  { value: number; index: number } & HTMLProps<HTMLDivElement>
> = (props) => {
  const { children, value, index, ...other } = props;

  const isActive = value === index;

  if (!isActive) return null;

  return (
    <div
      role="tabpanel"
      hidden={!isActive}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {isActive && children}
    </div>
  );
};

export default MyProductCard;
