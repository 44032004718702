import React, {
  createRef,
  FC,
  forwardRef,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import Slick, { Settings } from "react-slick";
import MediaCarouselSkeleton from "./MediaCarouselSkeleton";
import stl from "./MediaCarousel.module.scss";
import cn from "classnames";
import { Product } from "../../endpoints/products/_models/Product.model";

const SLIDES_IN_PAGINATION = 5;

interface SlideData {
  slide: ReactNode;
  paginationSlide: ReactNode;
}

interface Props extends Settings {
  product: Product | null;
}
export type MediaCarouselProps = Props;
const MediaCarousel: FC<Props> & { Skeleton: FC } = ({
  children,
  product,
  ...props
}) => {
  const [mainRef, setMainRef] = useState<Slick | null>(null);
  const [paginationRef, setPaginationRef] = useState<Slick | null>(null);

  const [videoRefs, setVideoRefs] = useState<RefObject<HTMLVideoElement>[]>([]);

  const slides = useMemo<SlideData[]>(() => {
    setVideoRefs([]);

    const res: SlideData[] = [];

    product?.images.forEach(({ src, alt }) => {
      res.push({
        slide: <ImageCard key={src} src={src} alt={alt} />,
        paginationSlide: <ImagePaginationCard key={src} src={src} alt={alt} />,
      });
    });

    product?.videos.forEach(({ src, alt, icon }) => {
      const ref = createRef<HTMLVideoElement>();
      res.push({
        slide: <VideoCard key={src} src={src} ref={ref} />,
        paginationSlide: <VideoPaginationCard key={src} src={icon} />,
      });
      setVideoRefs((refs) => [...refs, ref]);
    });

    return res;
  }, [product]);

  // slick have some problem with setting first slide on first render
  // in experimental way found that first slide have -1 index, a bit strange, but now works fine
  useEffect(() => {
    if (mainRef && paginationRef && slides.length > 0) {
      if (mainRef) mainRef.slickGoTo(-1, true);
      setTimeout(() => {
        if (mainRef) mainRef.slickGoTo(0, true);
      }, 200);
      setTimeout(() => {
        if (mainRef) mainRef.slickGoTo(-1, true);
      }, 500);
    }
  }, [mainRef, paginationRef, slides]);

  // const isInfinite = slides.length > SLIDES_IN_PAGINATION;

  const pauseAllVideos = () => {
    videoRefs.forEach((video) => {
      video.current?.pause();
    });
  };

  return (
    <>
      <Slick
        className={stl.mainSlider}
        asNavFor={paginationRef ?? undefined}
        ref={(ref) => {
          setMainRef(ref);
        }}
        infinite={false}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        centerMode={false}
        afterChange={pauseAllVideos}
        {...props}
        children={slides.map(({ slide }) => slide)}
      />
      <Slick
        className={stl.paginationSlider}
        asNavFor={mainRef ?? undefined}
        ref={(ref) => {
          setPaginationRef(ref);
        }}
        infinite={false}
        speed={500}
        slidesToShow={SLIDES_IN_PAGINATION}
        slidesToScroll={1}
        focusOnSelect
        centerMode={false}
        {...props}
        children={slides.map(({ paginationSlide }) => paginationSlide)}
      />
    </>
  );
};

MediaCarousel.Skeleton = MediaCarouselSkeleton;

export default MediaCarousel;

const ImageCard: FC<{ src: string; alt?: string }> = ({ src, alt }) => {
  return (
    <div className={cn(stl.slide, stl.image)}>
      <img src={src} alt={alt} />
    </div>
  );
};
const ImagePaginationCard: FC<{ src: string; alt?: string }> = ({
  src,
  alt,
}) => {
  return (
    <div className={cn(stl.slide, stl.image)}>
      <img src={src} alt={alt} />
    </div>
  );
};
const VideoCard = forwardRef<HTMLVideoElement, { src: string }>(
  function VideoCard({ src }, ref) {
    return (
      <div className={cn(stl.slide, stl.video)}>
        <video src={src} controls={true} ref={ref}>
          <source src={src} />
        </video>
      </div>
    );
  }
);
const VideoPaginationCard: FC<{ src: string }> = () => {
  return (
    <div className={cn(stl.slide, stl.video)}>
      {/*<img src={src} alt={""} />*/}
    </div>
  );
};
