import { AppConfig } from "../config";

export const replaceLocationPathBasename = (
  path: string,
  basename: string
): string => {
  const arr = path.split("/");
  const currentBaseName = getLocationPathBasename(path);

  if (currentBaseName.length) {
    if (basename.length) arr[1] = basename;
    else arr.splice(1, 1);
  } else {
    if (basename.length) arr.splice(1, 0, basename);
  }

  return arr.join("/");
};

export const getLocationPathBasename = (path: string): string => {
  const arr = path.split("/");

  return arr[1].length === 2 ? arr[1] : "";
};

export const getCurrentLocaleFromPath = ():
  | typeof AppConfig["locales"][0]
  | undefined => {
  return AppConfig.locales.find(
    ({ urlBasename }) =>
      urlBasename === getLocationPathBasename(window.location.pathname)
  );
};
