import { RouteProps } from "react-router-dom";
import { authenticationRoute } from "./Authenticate/route";
import { applicationRoute } from "./Application/route";
import { registrationRoute } from "./Registration/route";
import { recoveryRoute } from "./Recovery/route";

const routes: RouteProps[] = [
  authenticationRoute,
  applicationRoute,
  registrationRoute,
  recoveryRoute,
];

export default routes;
