import {
  createStore,
  Effect,
  Event,
  createEffect,
  createEvent,
} from "effector";
import { EndpointError } from "./EndpointError";
import axios, { AxiosResponse, CancelToken } from "axios";

type State<Data> = {
  pending: boolean;
  error: null | EndpointError;
  data: null | Data;
};
export type EndpointState<Data> = State<Data>;

export class EndpointRequest<
  RequestParams extends Record<string, any> | undefined | null,
  ResponseData extends Record<string, any> | undefined | null,
  Data extends undefined | null | Record<string, any> = ResponseData,
  Params extends undefined | null | Record<string, any> = RequestParams
> {
  private initialState: State<Data> = {
    pending: false,
    error: null,
    data: null,
  };

  public readonly reset = createEvent();

  public readonly store = createStore<State<Data>>(this.initialState).reset(
    this.reset
  );

  private readonly setPending: Event<boolean>;
  private readonly setError: Event<null | EndpointError>;
  private readonly setData: Event<null | Data>;

  call: Effect<Params, Data, EndpointError>;

  private cancelToken = axios.CancelToken.source();
  cancel = this.cancelToken.cancel;

  constructor(
    name: string,
    fn: (
      v: RequestParams,
      cancelToken: CancelToken
    ) => Promise<AxiosResponse<ResponseData>>,
    transformResponse?: (
      resData: AxiosResponse<ResponseData>,
      reqData: Params,
      prevState: State<Data>
    ) => Data,
    transformRequest?: (params: Params) => RequestParams
  ) {
    this.setPending = createEvent(`${name}:PENDING`);
    this.setError = createEvent(`${name}:ERROR`);
    this.setData = createEvent(`${name}:DATA`);

    this.store.on(this.setPending, (s, pending) => ({ ...s, pending }));
    this.store.on(this.setError, (s, error) => ({ ...s, error }));
    this.store.on(this.setData, (s, data) => ({ ...s, data }));

    this.call = createEffect<Params, Data, EndpointError>(name, {
      handler: async (params: Params) => {
        const prevState = this.store.getState();

        this.setError(null);
        // this.setData(null);

        const cancelToken = axios.CancelToken.source();

        this.cancelToken = cancelToken;
        this.cancel = cancelToken.cancel;

        this.setPending(true);
        let response: null | AxiosResponse<ResponseData> | EndpointError;
        try {
          const res = await fn(
            transformRequest
              ? transformRequest(params)
              : (params as RequestParams), // TODO: use conditional typing for this
            cancelToken.token
          );
          response = res;
        } catch (e) {
          response = new EndpointError(e);
        }

        if (response instanceof EndpointError) {
          this.setError(response);
          this.setPending(false);
          throw response;
        } else {
          const transformedResponse = transformResponse
            ? transformResponse(response, params, prevState)
            : (response.data as Data); // TODO: use conditional typing for this
          this.setData(transformedResponse);
          this.setPending(false);

          return transformedResponse as Data;
        }
      },
    });
  }
}
