import ReactDOM from "react-dom";
import React, { FC, Suspense } from "react";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { ChangePasswordEndpoint } from "../endpoints/auth/changePassword";
import * as yup from "yup";
import { AppConfig } from "../config";
import { useFormik } from "formik";
import { callPop } from "../App";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Providers from "../Providers";

export const changePassword = (): void => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const unmount = () => {
    const unmountResult = ReactDOM.unmountComponentAtNode(container);
    if (unmountResult && container.parentNode) {
      container.parentNode.removeChild(container);
    }
  };

  setTimeout(() => {
    ReactDOM.render(
      <Suspense fallback={<Loading />}>
        <ChangePasswordModal onClose={unmount} />
      </Suspense>,
      container
    );
  });
};

const ChangePasswordModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation(["authModule_login", "ff", "base"]);
  const { pending } = useStore(ChangePasswordEndpoint.store);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t("ff:common.er"))
      .min(
        AppConfig.minPasswordLength,
        t("ff:common.emin", { v: AppConfig.minPasswordLength })
      ),
    passwordRepeat: yup
      .string()
      .required(t("ff:common.er"))
      .min(
        AppConfig.minPasswordLength,
        t("ff:common.emin", { v: AppConfig.minPasswordLength })
      )
      .oneOf(
        [yup.ref("password")],
        t("ff:common.esame", { v: t("authModule_login:password") })
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordRepeat: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      ChangePasswordEndpoint.call({ password: values.password }).then(() => {
        callPop?.(t("base:changePasswordModal.SuccessMessage"), {
          variant: "success",
        });
        setTimeout(onClose, 1000);
      });
    },
  });

  return (
    <Providers>
      <Dialog open={true} onClose={onClose}>
        <form onSubmit={formik.handleSubmit} style={{ position: "relative" }}>
          <DialogTitle id="form-dialog-title">
            {t("base:changePasswordModal.Title")}
          </DialogTitle>
          <DialogContent>
            {pending && <Loading withSuperBg />}

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="password"
                  label={t("authModule_login:password")}
                  name={"password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="password"
                  label={t("authModule_login:passwordRepeat")}
                  name={"passwordRepeat"}
                  value={formik.values.passwordRepeat}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.passwordRepeat &&
                    Boolean(formik.errors.passwordRepeat)
                  }
                  helperText={
                    formik.touched.passwordRepeat &&
                    formik.errors.passwordRepeat
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {t("base:changePasswordModal.btnCancel")}
            </Button>
            <Button type="submit" color="secondary" variant={"contained"}>
              {t("base:changePasswordModal.btnSubmit")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Providers>
  );
};
