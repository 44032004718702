import React, { FC, HTMLProps } from "react";
import stl from "./index.module.scss";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Bg from "../../assets/images/bg-banner.svg";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";

export type BannerProps = Props;
interface Props extends HTMLProps<HTMLDivElement> {
  title: string;
  titleSize?: "lg";
  subtitle?: string;
  breadcrumbs?: { title: string; to?: string }[];
  pending?: boolean;
}
const Banner: FC<Props> = ({
  title,
  titleSize,
  subtitle,
  breadcrumbs,
  pending,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(className, stl.block)}
      style={{ backgroundImage: `url(${Bg})` }}
      {...props}
    >
      <Container fixed>
        {breadcrumbs && (
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map(({ title, to }) => {
              return to ? (
                <Link key={title} component={NavLink} to={to}>
                  {title}
                </Link>
              ) : (
                <Link key={title} color="textPrimary" aria-current="page">
                  {title}
                </Link>
              );
            })}
          </Breadcrumbs>
        )}

        <Grid container>
          <Grid item md={6} xs={12}>
            {pending ? (
              <Typography variant="h2">
                <Skeleton variant="text" animation="wave" width={"90%"} />
              </Typography>
            ) : (
              <Typography
                variant="h1"
                className={cn(stl.title, {
                  [stl.titleLarge]: titleSize === "lg",
                })}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
          </Grid>
        </Grid>
        {subtitle && <i>{subtitle}</i>}
      </Container>
    </div>
  );
};

export default Banner;
