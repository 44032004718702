import React, { FC, PropsWithChildren } from "react";
import cn from "classnames";
import stl from "./Top.desktop.module.scss";
import Navigation from "./Navigation";
import { useStore } from "effector-react";
import { OptionsEndpoint } from "../../../endpoints/shared";
import { $isAuthorized } from "../../../store/shared";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { authenticationRoute } from "../../../modules/Auth/Authenticate/route";
import { useTranslation } from "react-i18next";
import { $logo } from "../../../endpoints/shared";
import User from "./User";
import Cart from "./Cart";

interface Props {
  variant?: "separate";
  className?: PropsWithChildren<HTMLDivElement>["className"];
  color?: "transparent";
}
const TopDesktop: FC<Props> = ({ className, variant, color }) => {
  const { t } = useTranslation();
  const { data } = useStore(OptionsEndpoint.store);
  const isAuthorized = useStore($isAuthorized);

  const logo = useStore($logo);

  const separate = variant === "separate";

  const transparent = separate ? false : color === "transparent";

  return (
    <header
      className={cn(className, stl.block, {
        [stl.separate]: separate,
        [stl.transparent]: transparent,
      })}
    >
      <div className={stl.inner}>
        <div className={stl.left}>
          <NavLink to={"/"}>
            <img
              src={transparent ? logo?.light : logo?.dark}
              alt=""
              title={data?.login_screen_header}
            />
          </NavLink>
        </div>
        <div className={stl.center}>{!separate && <Navigation />}</div>
        <div className={stl.right}>
          <div>
            <User />
          </div>
          <div>
            <Cart />
          </div>
          <div>
            {!isAuthorized && separate && (
              <Button
                color="secondary"
                variant="contained"
                component={NavLink}
                to={authenticationRoute.path}
              >
                {t("authenticate")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopDesktop;
