import React, { FC, useEffect, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { useStore } from "effector-react";
import {
  TermsAndConditionsEndpoint,
  OptionsEndpoint,
} from "../../endpoints/shared";
import DefaultLayout from "../../layouts/Default";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { registrationRoute } from "../../modules/Auth/Registration/route";
import stl from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { DocumentCard } from "../../cards";
import styledStl from "../../assets/styles/styledHtmlContent.module.scss";
import basicLayoutStl from "../../assets/styles/basicLayoutSettings.module.scss";

const TermsAndConditions: FC = () => {
  const { t } = useTranslation();
  const { data, pending: dataPending } = useStore(
    TermsAndConditionsEndpoint.store
  );
  const { data: optionsData, pending: optionsPending } = useStore(
    OptionsEndpoint.store
  );

  const pending = dataPending || optionsPending;

  useEffect(() => {
    if (optionsData) {
      if (optionsData.service_terms) {
        TermsAndConditionsEndpoint.call({ _id: optionsData.service_terms });
      }
    } else {
      OptionsEndpoint.call({}).then(({ service_terms }) => {
        if (service_terms) {
          TermsAndConditionsEndpoint.call({ _id: service_terms });
        }
      });
    }
  }, [optionsData]);

  const renderContent = data ? (
    <>
      <div
        className={styledStl.content}
        dangerouslySetInnerHTML={{ __html: data?.content }}
      />
      <Button
        color="secondary"
        variant="contained"
        component={NavLink}
        to={registrationRoute.path}
      >
        {t("register")}
      </Button>
    </>
  ) : null;

  const renderAside = data
    ? data.aside.map((aside) => (
        <Fragment key={aside.id}>
          <h4 className={stl.asideHeading}>{aside.title}</h4>
          {aside.content.map(({ icon, name, url, subtype, filesize }) => (
            <DocumentCard
              style={{ margin: "15px 0" }}
              key={name + url}
              name={name}
              icon={icon}
              url={url}
              subtype={subtype}
              filesize={filesize}
            />
          ))}
        </Fragment>
      ))
    : null;

  return (
    <DefaultLayout variant={"separate"} mainBg={"secondary"}>
      <div className={basicLayoutStl.section}>
        {pending ? (
          <Typography variant="h2">
            <Skeleton variant="text" animation="wave" width={"30%"} />
          </Typography>
        ) : (
          <h1 className={stl.pageHeading}>{data?.title}</h1>
        )}

        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            {pending ? <ContentSkeleton /> : renderContent}
          </Grid>
          <Grid item md={4} xs={12}>
            <div className={stl.aside}>
              {pending ? <AsideSkeleton /> : renderAside}
            </div>
          </Grid>
        </Grid>
      </div>
    </DefaultLayout>
  );
};

export default TermsAndConditions;

const ContentSkeleton: FC = () => (
  <>
    <Typography variant="h2">
      <Skeleton variant="text" animation="wave" width={"30%"} />
    </Typography>
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" />
    <Typography variant="h2">
      <Skeleton variant="text" animation="wave" width={"50%"} />
    </Typography>
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" width={"30%"} />
    <Skeleton variant="text" animation="wave" width={"30%"} />
    <Typography variant="h2">
      <Skeleton variant="text" animation="wave" width={"70%"} />
    </Typography>
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" width={"50%"} />
    <Skeleton variant="text" animation="wave" width={"30%"} />
    <Typography variant="h2">
      <Skeleton variant="text" animation="wave" width={"50%"} />
    </Typography>
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" width={"50%"} />
    <Typography variant="h2">
      <Skeleton variant="text" animation="wave" width={"30%"} />
    </Typography>
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" width={"50%"} />
    <Typography variant="h2">
      <Skeleton variant="text" animation="wave" width={"60%"} />
    </Typography>
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" width={"50%"} />
    <Skeleton variant="text" animation="wave" width={"30%"} />
    <Skeleton
      variant="rect"
      height={50}
      width={150}
      animation="wave"
      style={{ marginTop: 30 }}
    />
  </>
);

const AsideSkeleton: FC = () => (
  <>
    <Typography variant="h4">
      <Skeleton variant="text" animation="wave" width={"80%"} />
    </Typography>
    <Typography variant="h4">
      <Skeleton
        variant="text"
        animation="wave"
        width={"80%"}
        style={{ marginBottom: 10 }}
      />
    </Typography>
    <Skeleton
      variant="rect"
      height={130}
      animation="wave"
      style={{ marginBottom: 20 }}
    />
    <Skeleton
      variant="rect"
      height={130}
      animation="wave"
      style={{ marginBottom: 20 }}
    />
  </>
);
